import React, { useContext, useEffect, useState } from 'react'
import { FaEye, FaFilePdf, FaFileDownload } from 'react-icons/fa'
import { Auth } from '../../contexts/allContext'
import { formatDate } from '../../utils/dateFormat'
import useDebounce from '../../utils/useDebounce'
import Table from '../Resources/Table/Table'
import Filter from './Filter/Filter'
import Form from './Form/Form'
import Pdf from './Pdf/Pdf'
import classes from './Sme.module.css'

const SME = () => {
    const { stateAuth } = useContext(Auth)
    const MIN = 0
    const MAX = 2000000
    const token = stateAuth.token
    const [value, setValue] = useState([])
    const apiV1 = process.env.REACT_APP_API_URL_V1
    const [form, setForm] = useState(false)
    const [current, setCurrent] = useState(0)
    const [select, setSelect] = useState(1)
    const [refetch, setRefetch] = useState(false)
    const [link, setLink] = useState(null)
    const [customerName, setCustomerName] = useState('')
    const debouncedCustomerName = useDebounce(customerName, 800)
    const [phone, setPhone] = useState('')
    const debouncedPhoneValue = useDebounce(phone, 800)
    const [bankName, setBankName] = useState('')
    const debouncedBankValue = useDebounce(bankName, 800)
    const [loan, setLoan] = useState('')
    const debouncedLoanValue = useDebounce(loan, 800)
    const [dateStart, setDateStart] = useState('')
    const debouncedDateStartValue = useDebounce(dateStart, 800)
    const [dateEnd, setDateEnd] = useState('')
    const debouncedDateEndValue = useDebounce(dateEnd, 800)
    const [amount, setAmount] = useState([MIN, MAX])
    const debouncedAmountValue = useDebounce(amount, 800)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${apiV1}/loans/?user_name=${debouncedCustomerName}&user_phone=${debouncedPhoneValue}&bank_name=${debouncedBankValue}&loan_name=${debouncedLoanValue}&start_date=${debouncedDateStartValue}&end_date=${debouncedDateEndValue}&amount_low=${debouncedAmountValue[0]}&amount_high=${debouncedAmountValue[1]}&skip=0&limit=10`,
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                const data = await response.json()

                if (response.ok) {
                    setValue(data)
                } else {
                    setValue([])
                }
            } catch (e) {
                setValue([])
            }
        }
        fetchData()
        if (refetch) {
            setTimeout(() => {
                setRefetch(false)
            }, 3000)
        }
    }, [
        apiV1,
        token,
        refetch,
        debouncedLoanValue,
        debouncedBankValue,
        debouncedCustomerName,
        debouncedPhoneValue,
        debouncedDateStartValue,
        debouncedDateEndValue,
        debouncedAmountValue,
    ])
    const handleDownload = (string) => {
        const { SmeDocument } = string
        const trade = SmeDocument?.trade_license_string || ''
        const Incorporation = SmeDocument?.incorporation_certificate_string || ''
        const business = SmeDocument?.business_tin_string
        const fetchData = async () => {
            try {
                const response1 = await fetch(`${apiV1}/temporary/url?service_name=loan&file_string=${trade}`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const response2 = await fetch(`${apiV1}/temporary/url?service_name=loan&file_string=${Incorporation}`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const response3 = await fetch(`${apiV1}/temporary/url?service_name=loan&file_string=${business}`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data1 = await response1.json()
                const data2 = await response2.json()
                const data3 = await response3.json()
                if (response1.ok && response2.ok && response3.ok) {
                    setLink((prev) => ({
                        trade: data1?.temporary_url,
                        Incorporation: data2?.temporary_url,
                        business: data3?.temporary_url,
                    }))
                } else {
                    setLink(null)
                }
            } catch (error) {}
        }
        fetchData()
    }
    useEffect(() => {
        if (link !== null) {
            for (let key in link) {
                window.open(link[key], '_blank')
            }

            setLink(null)
        }
    }, [link])

    return (
        <div className={classes.container}>
            <h2>SME Loan ({value[0]?.results || 0})</h2>
            <Filter
                setCustomerName={setCustomerName}
                setPhone={setPhone}
                setBankName={setBankName}
                setLoan={setLoan}
                setDateStart={setDateStart}
                setDateEnd={setDateEnd}
                amount={amount}
                setAmount={setAmount}
            />
            <div className={classes.list}>
                <Table>
                    <thead>
                        <tr>
                            <th>Sl.</th>
                            <th>ID</th>
                            <th>Customer Name</th>
                            <th>Customer Phone</th>
                            <th>Bank Name</th>
                            <th>Loan Name</th>
                            <th>Loan Amount</th>
                            <th>Loan Tenure</th>
                            <th>Application Date</th>
                            <th className={classes.actionHeader}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {value[1]?.map((data, index) => {
                            return (
                                <tr className={classes.row} key={data?.SmeLoan.id}>
                                    <td>{index + 1}</td>
                                    <td>BIZ-L{data?.SmeLoan?.id}</td>
                                    <td>{data?.SmePersonal.name}</td>
                                    <td>{data?.SmePersonal.phone}</td>
                                    <td>{data?.SmeBank.bank_name}</td>
                                    <td>{data?.SmeBank.loan_name}</td>
                                    <td>{data?.SmeBank.loan_amount}</td>
                                    <td>{data?.SmeBank.loan_tenure ? data?.SmeBank.loan_tenure + ' Years' : '-'} </td>
                                    <td>{formatDate(data?.SmeLoan.created_at)}</td>

                                    <td className={classes.action}>
                                        <button
                                            onClick={() => {
                                                setForm(!form)
                                                setCurrent(index)
                                                setSelect(1)
                                            }}>
                                            <FaFilePdf size={16} />
                                        </button>
                                        <button
                                            onClick={() => {
                                                setCurrent(index)
                                                handleDownload(value[1][current])
                                            }}>
                                            <FaFileDownload size={16} />
                                        </button>
                                        <button
                                            onClick={() => {
                                                setForm(!form)
                                                setCurrent(index)
                                                setSelect(3)
                                            }}>
                                            <FaEye size={16} />
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>

            {form && select === 3 && (
                <Form form={form} setForm={setForm} data={value[1][current]} setRefetch={setRefetch} />
            )}
            {form && select === 1 && <Pdf form={form} setForm={setForm} data={value[1][current]} />}
        </div>
    )
}

export default SME
