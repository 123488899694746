import React from 'react'
import { FaThumbtack } from 'react-icons/fa'
import classes from './FormV1.module.css'

const Formv1 = ({ children, title }) => {
    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <FaThumbtack className={classes.icon} />
                <h1>{title}</h1>
            </div>
            <div className={classes.formContainer}>{children}</div>
        </div>
    )
}

export default Formv1
