import React, { useContext, useState } from 'react'
import { Auth } from '../../../../contexts/allContext'
import { apiV1 } from '../../../../utils/api'
import classes from './Buyer.module.css'

const Buyer = () => {
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [error, setError] = useState('')
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token
    const handleEmailSelect = async (e) => {
        e.preventDefault()
        try {
            const response = await fetch(`${apiV1}/users/?email=${email}&skip=0&limit=10`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            const data = await response.json()
            if (response.ok && data[0]?.results > 0) {
                setName(data[1][0]?.name)
                setPhone(data[1][0]?.phone)
                setError('')
            }
            if (data[0]?.results === 0) {
                setError('User with the given email not found!')
                setName('')
                setPhone('')
            }
        } catch (e) {
            setError('User with the given email not found!')
        }
    }
    return (
        <div className={classes.container}>
            <div className={classes.whiteContainer}>
                <div className={classes.innerContainer}>
                    <div className={classes.email}>
                        <div>
                            <p>Select Buyer by Email</p>
                            <div className={classes.buyerInput}>
                                <input type="text" onChange={(e) => setEmail(e.target.value)} />

                                <div>
                                    <button onClick={handleEmailSelect} type="button">
                                        Select
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {error && (
                        <div>
                            <p className={classes.error}>{error}</p>
                        </div>
                    )}
                    {name && (
                        <div className={classes.half}>
                            <div>
                                <p>Name</p>
                                <div className={classes.fixedField}>{name}</div>
                            </div>
                            <div>
                                <p>Phone</p>
                                <div className={classes.fixedField}>{phone}</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Buyer
