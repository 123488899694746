import { SellerDefStorage, SellerStateStorage, SellerGetStorage } from '../storage/storage'

export const sellerState = SellerDefStorage('seller', { status: false })

export const sellerReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            SellerStateStorage('seller', { status: true })
            return JSON.parse(SellerGetStorage('seller'))
        case 'close':
            SellerStateStorage('seller', { status: false })
            return JSON.parse(SellerGetStorage('seller'))
        default:
            return state
    }
}
