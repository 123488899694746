import React, { useState } from 'react'
import classes from './Personal.module.css'

const Personal = ({ fullName, api, id, token, setFullName, email, setEmail, phone, setPhone }) => {
    const [submitStatus, setSubmitStatus] = useState(false)
    const [msg, setMsg] = useState('')
    const handleSubmit = async (e) => {
        e.preventDefault()
        const response = await fetch(`${api}/users/${id}`, {
            headers: {
                Accept: 'appllication/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: 'PATCH',
            body: JSON.stringify({
                name: fullName,
                phone: phone,
                email: email,
            }),
        })
        if (response.ok) {
            setSubmitStatus(true)
            setMsg('Form Updated!')
            setTimeout(() => {
                setSubmitStatus(false)
            }, 2000)
        } else {
            setSubmitStatus(true)
            setMsg('Failed to submit form, please try again later!')
            setTimeout(() => {
                setSubmitStatus(false)
            }, 2000)
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className={classes.wrapper}>
                <h1>Personal Information</h1>
                <div className={classes.innerContainer}>
                    <p>Full Name</p>
                    <input type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                    <div className={classes.half}>
                        <div>
                            <p>Email</p>
                            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div>
                            <p>Phone</p>
                            <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </div>
                    </div>
                </div>
                {submitStatus && (
                    <p
                        className={classes.submitMessage}
                        style={{ color: `${msg?.substring(0, 2) === 'Fo' ? '#5cb484' : '#a10f0f'}` }}>
                        {msg}
                    </p>
                )}
                <div className={classes.submit}>
                    <button>Submit</button>
                </div>
            </div>
        </form>
    )
}

export default Personal
