import React from 'react'
import Form from '../Resources/Form/Form'

export default function Category({
    setCategoryName,
    setSubCategoryName,
    setSubSubCategoryName,
    setCategoryId,
    setCategoryIds,
    setSubCategoryId,
    categoryAll,
    subCategoryAll,
    handleCategory,
    handleSubCategory,
    handleSubSubCategory,
    mess,
}) {
    return (
        <div>
            <Form
                setCategoryName={setCategoryName}
                setSubCategoryName={setSubCategoryName}
                setSubSubCategoryName={setSubSubCategoryName}
                setCategoryId={setCategoryId}
                setCategoryIds={setCategoryIds}
                setSubCategoryId={setSubCategoryId}
                categoryAll={categoryAll}
                subCategoryAll={subCategoryAll}
                handleCategory={handleCategory}
                handleSubCategory={handleSubCategory}
                handleSubSubCategory={handleSubSubCategory}
                mess={mess}
            />
        </div>
    )
}
