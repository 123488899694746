import React from 'react'
import { LogIn } from '../components'

export default function LogInPage() {
    return (
        <div>
            <LogIn />
        </div>
    )
}
