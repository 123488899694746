import React, { useState } from 'react'
import CategoryList from './CategoryList/CategoryList'
import classes from './CategoryListEdit.module.css'
import SubCategoryList from './SubCategoryList/SubCategoryList'
import SubSubCategoryList from './SubSubCategoryList/SubSubCategoryList'

export default function CategoryListEdit(props) {
    const [click, setClick] = useState('1')
    return (
        <div className={classes.wrapper}>
            <div className={classes.button}>
                <div
                    className={click === '1' ? classes.buttonActive : classes.buttonInactive}
                    onClick={() => (click !== '1' ? setClick('1') : '')}>
                    Category
                </div>
                <div
                    className={click === '2' ? classes.buttonActive : classes.buttonInactive}
                    onClick={() => (click !== '2' ? setClick('2') : '')}>
                    Sub Category
                </div>
                <div
                    className={click === '3' ? classes.buttonActive : classes.buttonInactive}
                    onClick={() => (click !== '3' ? setClick('3') : '')}>
                    {' '}
                    Sub Sub Category
                </div>
            </div>
            {click === '1' && (
                <CategoryList
                    category={props.category}
                    handleClick={props.handleClick}
                    clickEdit={props.clickEdit}
                    setClickEdit={props.setClickEdit}
                    loading={props.loading}
                    setLoading={props.setLoading}
                    activePopUp={props.activePopUp}
                    setActivePopUp={props.setActivePopUp}
                />
            )}
            {click === '2' && (
                <SubCategoryList
                    subCategory={props.subCategory}
                    categoryOption={props.categoryOption}
                    categorySelect={props.categorySelect}
                    setCategorySelect={props.setCategorySelect}
                    setSubCategorySelect={props.setSubCategorySelect}
                    handleClick={props.handleClick}
                    clickEdit={props.clickEdit}
                    setClickEdit={props.setClickEdit}
                    loading={props.loading}
                    setLoading={props.setLoading}
                    activePopUp={props.activePopUp}
                    setActivePopUp={props.setActivePopUp}
                />
            )}
            {click === '3' && (
                <SubSubCategoryList
                    subSubCategory={props.subSubCategory}
                    categoryOption={props.categoryOption}
                    categorySelect={props.categorySelect}
                    setCategorySelect={props.setCategorySelect}
                    subCategoryOption={props.subCategoryOption}
                    subCategorySelect={props.subCategorySelect}
                    setSubCategorySelect={props.setSubCategorySelect}
                    handleClick={props.handleClick}
                    clickEdit={props.clickEdit}
                    setClickEdit={props.setClickEdit}
                    loading={props.loading}
                    setLoading={props.setLoading}
                    activePopUp={props.activePopUp}
                    setActivePopUp={props.setActivePopUp}
                />
            )}
        </div>
    )
}
