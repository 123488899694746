import React from 'react'
import Confirm from './Confirm/Confirm'
import classes from './PopUp.module.css'
import UpdateSellerInfo from './UpdateSellerInfo/UpdateSellerInfo'

export default function PopUp(props) {
    return (
        <div className={classes.model}>
            <div className={classes.overlay}></div>
            {props.btnClickPopUp === '1' && (
                <UpdateSellerInfo
                    sellerName={props.sellerName}
                    userId={props.userId}
                    sellerId={props.sellerId}
                    active={props.active}
                    featured={props.featured}
                    verified={props.verified}
                    basic={props.basic}
                    standard={props.standard}
                    premium={props.premium}
                    check={props.check}
                    setCheck={props.setCheck}
                    activePopUp={props.activePopUp}
                    setActivePopUp={props.setActivePopUp}
                    data={props.data}
                />
            )}

            {props.btnClickPopUp === '2' && (
                <Confirm
                    setActivePopUp={props.setActivePopUp}
                    message={props.message}
                    handleDelete={props.handleDelete}
                    categoryId={props.categoryId}
                />
            )}
        </div>
    )
}
