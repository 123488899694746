import React, { useState } from 'react'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import classes from './Role.module.css'

const Role = () => {
    const [role, setRole] = useState('')
    const handleSubmit = async (e) => {
        e.preventDefault()
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className={classes.wrapper}>
                <h1>Update User Role</h1>
                <div className={classes.innerContainer}>
                    <div className={classes.inputContainer}>
                        <div className={classes.half}>
                            <p>Search by Phone Number</p>
                            <input type="text" />
                        </div>
                        <div>
                            <p>User Name</p>
                            <div className={classes.fixedField}></div>
                        </div>
                        <div>
                            <p>User Role</p>
                            <div className={classes.fixedField}></div>
                        </div>
                    </div>
                    <div className={classes.selectContainer}>
                        <p>Select For Update</p>
                        <select
                            className={classes.selector}
                            defaultValue={'DEFAULT'}
                            onChange={(e) => setRole(e.target.value)}>
                            <option disabled value="DEFAULT">
                                Select
                            </option>
                            <option value="admin">Admin</option>
                            <option value="buyer">Buyer</option>
                            <option value="seller">Seller</option>
                        </select>
                        <MdOutlineKeyboardArrowDown className={classes.selectArrow} size={20} />
                    </div>
                </div>
                <div className={classes.submit}>
                    <button type="submit">Update</button>
                </div>
            </div>
        </form>
    )
}

export default Role
