import React, { useContext } from 'react'
import { IoClose } from 'react-icons/io5'
import { Auth } from '../../../contexts/allContext'
import PopupV1 from '../../Resources/PopupV1/PopupV1'
import BankDetails from './BankDetails/BankDetails'
import Business from './Business/Business'
import Document from './Document/Document'
import classes from './Form.module.css'
import Guarantor from './Guarantor/Guarantor'
import Personal from './Personal/Personal'

const Form = ({ setForm, form, data, setRefetch }) => {
    const apiV1 = process.env.REACT_APP_API_URL_V1
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token
    return (
        <PopupV1 setForm={setForm} form={form} width="60%">
            <div className={classes.close}>
                <div className={classes.header}>
                    <p>
                        SME Finance Loan Submitted By <span>{data?.SmePersonal?.name}</span>
                    </p>
                </div>
                <button onClick={() => setForm(!form)}>
                    <IoClose size={20} />
                </button>
            </div>
            <div className={classes.content}>
                <hr />
                <div className={classes.main}>
                    <div>
                        <input type="checkbox" id="box1" />
                        <label className={classes.formItem} htmlFor="box1">
                            Bank Details
                        </label>
                        <BankDetails bankDetails={data?.SmeBank} api={apiV1} token={token} setRefetch={setRefetch} />
                    </div>
                    <div>
                        <input type="checkbox" id="box2" />
                        <label className={classes.formItem} htmlFor="box2">
                            Personal Information
                        </label>
                        <Personal
                            personalDetails={data?.SmePersonal}
                            api={apiV1}
                            token={token}
                            setRefetch={setRefetch}
                        />
                    </div>
                    <div>
                        <input type="checkbox" id="box3" />
                        <label className={classes.formItem} htmlFor="box3">
                            Business Information
                        </label>
                        <Business
                            businessDetails={data?.SmeBusiness}
                            api={apiV1}
                            token={token}
                            setRefetch={setRefetch}
                        />
                    </div>
                    <div>
                        <input type="checkbox" id="box4" />
                        <label className={classes.formItem} htmlFor="box4">
                            Guarantor Information
                        </label>
                        <Guarantor
                            guarantorDetails={data?.SmeGuarantor}
                            api={apiV1}
                            token={token}
                            setRefetch={setRefetch}
                        />
                    </div>
                    <div>
                        <input type="checkbox" id="box5" />
                        <label className={classes.formItem} htmlFor="box5">
                            All Documents
                        </label>
                        <Document documentLists={data?.SmeDocument} setRefetch={setRefetch} />
                    </div>
                </div>
            </div>
            <div className={classes.closeButton}>
                <button onClick={() => setForm(!form)}>Close</button>
            </div>
        </PopupV1>
    )
}

export default Form
