import React, { useContext } from 'react'
import { useState, useEffect } from 'react'
import { Layout, CategoryListEdit } from '../components'
import { Auth, UserInfo } from '../contexts/allContext'
import { getCategory, getSubcategoryByCategoryId, getSubsubcategoryBySubcategoryId } from '../utils/fetchData'

export default function CategoryListPage() {
    const { stateAuth, dispatchAuth } = useContext(Auth)
    const { dispatchUser } = useContext(UserInfo)
    let token = stateAuth.token

    const [category, setCategory] = useState([])
    const [subCategory, setSubCategory] = useState([])
    const [subSubCategory, setSubSubCategory] = useState([])
    const [check, setCheck] = useState(false)
    const [categorySelect, setCategorySelect] = useState('')
    const [subCategorySelect, setSubCategorySelect] = useState('')
    const [categoryOption, setCategoryOption] = useState([])
    const [subCategoryOption, setSubCategoryOption] = useState([])

    useEffect(() => {
        // Function to fetch data
        const fetchData = () => {
            const categoryOptionList = []
            getCategory()
                .then((items) => {
                    setCategory(items)
                    items.map((e) => categoryOptionList.push({ id: e.id, label: e.name, value: e.name }))
                    setCategoryOption(categoryOptionList)
                    check && setCheck(false)
                })
                .catch((error) => {
                    // console.error('Error fetching data:', error)
                })
        }

        fetchData()
    }, [check])

    useEffect(() => {
        // Function to fetch data
        const fetchData = () => {
            const subCategoryOptionList = []
            getSubcategoryByCategoryId(categorySelect.id)
                .then((items) => {
                    setSubCategory(items)
                    items.map((e) => subCategoryOptionList.push({ id: e.id, label: e.name, value: e.name }))
                    setSubCategoryOption(subCategoryOptionList)
                    check && setCheck(false)
                })
                .catch((error) => {
                    // console.error('Error fetching data:', error)
                })
        }
        if (categorySelect) {
            fetchData()
        }
    }, [check, categorySelect])

    useEffect(() => {
        // Function to fetch data
        const fetchData = () => {
            getSubsubcategoryBySubcategoryId(subCategorySelect.id)
                .then((items) => {
                    setSubSubCategory(items)
                    check && setCheck(false)
                })
                .catch((error) => {
                    // console.error('Error fetching data:', error)
                })
        }
        if (subCategorySelect) {
            fetchData()
        }
    }, [check, subCategorySelect])

    const [clickEdit, setClickEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [activePopUp, setActivePopUp] = useState(false)

    // Update, Delete
    const handleClick = async (url, newName, method, status, url2, status2) => {
        const details = {
            name: newName,
        }
        try {
            if (token) {
                setLoading(true)
                if (method === 'DELETE') {
                    if (url2 !== '') {
                        const responseCheck = await fetch(url2, {
                            method: 'GET',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                        })
                        const logCheck = await responseCheck.json()
                        if (responseCheck.ok) {
                            if (logCheck.length >= 1) {
                                alert(status2)
                            } else {
                                const response = await fetch(url, {
                                    method: method,
                                    headers: {
                                        Accept: 'application/json',
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ${token}`,
                                    },
                                })
                                const log = await response.json()
                                if (response.ok) {
                                    setCheck(true)
                                    loading && setLoading(false)
                                    clickEdit && setClickEdit(false)
                                } else {
                                    console.log(log.context)
                                }
                            }
                        }
                    } else {
                        const response = await fetch(url, {
                            method: method,
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        })
                        const log = await response.json()
                        if (response.ok) {
                            setCheck(true)
                            loading && setLoading(false)
                            clickEdit && setClickEdit(false)
                        } else {
                            console.log(log.context)
                        }
                    }
                } else {
                    const response = await fetch(url, {
                        method: method,
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(details),
                    })
                    const log = await response.json()
                    if (response.ok) {
                        setCheck(true)
                        loading && setLoading(false)
                        clickEdit && setClickEdit(false)
                    } else {
                        console.log(log.context)
                    }
                }
            } else {
                dispatchAuth({ type: 'remove' })
                dispatchUser({ type: 'remove' })
            }
        } catch {}
    }

    return (
        <Layout>
            <CategoryListEdit
                category={category}
                categoryOption={categoryOption}
                categorySelect={categorySelect}
                setCategorySelect={setCategorySelect}
                subCategory={subCategory}
                setSubCategorySelect={setSubCategorySelect}
                subSubCategory={subSubCategory}
                subCategoryOption={subCategoryOption}
                subCategorySelect={subCategorySelect}
                // update, delete
                handleClick={handleClick}
                clickEdit={clickEdit}
                setClickEdit={setClickEdit}
                loading={loading}
                setLoading={setLoading}
                activePopUp={activePopUp}
                setActivePopUp={setActivePopUp}
            />
        </Layout>
    )
}
