import React, { useState } from 'react'
import Formv1 from '../Resources/FormV1/Formv1'
import Buyer from './Buyer/Buyer'
import classes from './CreateOrder.module.css'
import Delivery from './Delivery/Delivery'
import Payment from './Payment/Payment'
import Selection from './Selection/Selection'
import Shipment from './Shipment/Shipment'

const CreateOrder = () => {
    const [district, setDistrict] = useState('')
    const [subDistrict, setSubDistrict] = useState('')
    const [buyerDivision, setBuyerDivision] = useState('')
    const [buyerPhone, setBuyerPhone] = useState('')
    const [buyerAddress, setBuyerAddress] = useState('')
    const handleSubmit = async (e) => {
        e.preventDefault()
    }
    return (
        <div>
            <Formv1 title={'Create Order'}>
                <form className={classes.formContainer} onSubmit={handleSubmit}>
                    <div className={classes.main}>
                        <div>
                            <input type="checkbox" id="box0" />
                            <label className={classes.formItem} htmlFor="box0">
                                Buyer Details
                            </label>
                            <Buyer />
                        </div>
                        <div>
                            <input type="checkbox" id="box1" />
                            <label className={classes.formItem} htmlFor="box1">
                                Product Details
                            </label>
                            <Selection />
                        </div>
                        <div>
                            <input type="checkbox" id="box2" />
                            <label className={classes.formItem} htmlFor="box2">
                                Shipment Details
                            </label>
                            <Shipment />
                        </div>
                        <div>
                            <input type="checkbox" id="box4" />
                            <label className={classes.formItem} htmlFor="box4">
                                Delivery Details
                            </label>
                            <Delivery />
                        </div>
                        <div>
                            <input type="checkbox" id="box3" />
                            <label className={classes.formItem} htmlFor="box3">
                                Payment Details
                            </label>
                            <Payment />
                        </div>
                    </div>
                    <div className={classes.submitContainer}>
                        <button>Submit</button>
                    </div>
                </form>
            </Formv1>
        </div>
    )
}

export default CreateOrder
