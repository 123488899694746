import { faBars, faGear, faTachometerAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaCoins } from 'react-icons/fa'
import { FaFirstOrder } from 'react-icons/fa'
import { FaBoxOpen } from 'react-icons/fa6'
import { MdMessage } from 'react-icons/md'
import { RiFileList3Line } from 'react-icons/ri'
import classes from '../Layout/Layout.module.css'
import NavBar from './NabBar/NavBar'
import SidebarOne from './SideBarOne/SidebarOne'

export default function Layout({ children }) {
    const sidebarItems = [
        {
            path: '/dashboard',
            icon: <FontAwesomeIcon icon={faTachometerAlt} title="Home" />,
            text: 'Dashboard',
        },
        {
            path: '/buyers',
            icon: <FontAwesomeIcon icon={faBars} title="List" />,
            text: 'Buyer List',
            child: {},
        },
        {
            path: '/seller-list',
            icon: <RiFileList3Line title="List" />,
            text: 'Seller List',
            child: {},
        },
        {
            path: '/category',
            icon: <FontAwesomeIcon icon={faUser} title="Profile" />,
            text: 'Category',
            child: {},
        },
        {
            path: '/orders',
            icon: <FaFirstOrder />,
            text: 'Order',
            child: {},
        },

        {
            path: '/products',
            icon: <FaBoxOpen title="Profile" />,
            text: 'Products',
            child: {},
        },
        {
            path: '/sme',
            icon: <FaCoins title="SME Loan" />,
            text: 'SME Loan',
        },
        {
            path: '/rfq-list',
            icon: <MdMessage title="Profile" />,
            text: 'Rfq List',
            child: {},
        },
        {
            path: '/setting',
            icon: <FontAwesomeIcon icon={faGear} title="Settings" />,
            text: 'Settings',
        },

        {
            path: '/profile',
            icon: <FontAwesomeIcon icon={faUser} title="Profile" />,
            text: 'Profile',
        },
    ]
    return (
        <div className={classes.layout}>
            <div>
                <SidebarOne sidebarItems={sidebarItems} />
            </div>
            <div className={classes.container}>
                <NavBar />
                <div className={classes.content}>{children}</div>
            </div>
        </div>
    )
}
