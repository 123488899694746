import { faThumbtack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import classes from './Dashboard.module.css'

export default function Dashboard({ activeSellers, pending, categories, subcategories, products }) {
    return (
        <div className={classes.content}>
            <Link to="/active-sellers">
                <div className={classes.cardBox}>
                    <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                    <div className={classes.wrap}>
                        <p className={classes.title}>Total Sellers</p>
                        <span className={classes.count}>{activeSellers || 0}</span>
                        <div className={classes.subInfo}>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </Link>

            <Link to="/inactive-sellers">
                {' '}
                <div className={classes.cardBox}>
                    <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                    <div className={classes.wrap}>
                        <p className={classes.title}>Pending Sellers</p>
                        <span className={classes.count}>{pending || 0}</span>
                        <div className={classes.subInfo}>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </Link>
            <Link to="/products">
                {' '}
                <div className={classes.cardBox}>
                    <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                    <div className={classes.wrap}>
                        <p className={classes.title}>Total Products</p>
                        <span className={classes.count}>{products || 0}</span>
                        <div className={classes.subInfo}>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </Link>

            <Link to="/category-list">
                <div className={classes.cardBox}>
                    <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                    <div className={classes.wrap}>
                        <p className={classes.title}>Total Category</p>
                        <span className={classes.count}>{categories || 0}</span>
                        <div className={classes.subInfo}>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </Link>
            <Link to="/category-list">
                <div className={classes.cardBox}>
                    <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                    <div className={classes.wrap}>
                        <p className={classes.title}>Total SubCategory</p>
                        <span className={classes.count}>{subcategories || 0}</span>
                        <div className={classes.subInfo}>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}
