import React from 'react'
import { Layout } from '../components'
import OrderList from '../components/OrderList/OrderList'

const OrderListPage = () => {
    return (
        <Layout>
            <OrderList />
        </Layout>
    )
}

export default OrderListPage
