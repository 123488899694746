import React from 'react'
import { Layout } from '../components'
import SME from '../components/Sme/Sme'

const Sme = () => {
    return (
        <Layout>
            <SME />
        </Layout>
    )
}

export default Sme
