import React from 'react'
import { useState, useEffect } from 'react'
import { Layout, Category } from '../components'
import { apiV1 } from '../utils/api'

export default function CategoryPage() {
    const [categoryName, setCategoryName] = useState('')
    const [categoryAll, setCategoryAll] = useState([])
    const [subCategoryAll, setSubCategoryAll] = useState([])
    const [subCategoryName, setSubCategoryName] = useState('')
    const [categoryId, setCategoryId] = useState('1')
    const [subSubCategoryName, setSubSubCategoryName] = useState('')
    const [categoryIds, setCategoryIds] = useState('1')
    const [subCategoryId, setSubCategoryId] = useState('')
    const [mess, setMess] = useState('')
    const auth = JSON.parse(localStorage.getItem('auth'))
    const token = auth.token

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiV1}/categories/`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })

                const data = await response.json()

                setCategoryAll(data)

                if (response.ok) {
                }
            } catch (e) {
                console.log(e)
            }
        }
        fetchData()
    }, [])

    // for SubCategory

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (categoryIds >= 1) {
                    const response = await fetch(`${apiV1}/categories/sub_category/${categoryIds}`, {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    })

                    const data = await response.json()

                    setSubCategoryAll(data)
                    setSubCategoryId(data[0]?.id)

                    if (response.ok) {
                    }
                }
            } catch (e) {
                console.log(e)
            }
        }
        fetchData()
    }, [categoryIds])

    // { Create New Category }

    const handleCategory = async (e) => {
        e.preventDefault()

        let categoryPost = await fetch(`${apiV1}/categories/`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: 'POST',
            body: JSON.stringify({
                name: categoryName,
            }),
        })

        if (categoryPost.ok) {
            // console.log('New Category Created')
            setMess('New Category Created')
            window.location.reload()
        } else {
        }
    }

    // create New SubCategory

    const handleSubCategory = async (e) => {
        e.preventDefault()

        let subCategoryPost = await fetch(`${apiV1}/categories/sub_category/`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: 'POST',
            body: JSON.stringify({
                name: subCategoryName,
                category_id: categoryId,
            }),
        })

        if (subCategoryPost.ok) {
            setMess('New SubCategory Created')
            window.location.reload()
        } else {
        }
    }
    // Create new SubSubCategory
    const handleSubSubCategory = async (e) => {
        e.preventDefault()
        let subSubCategoryPost = await fetch(`${apiV1}/categories/sub_sub_category/`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: 'POST',
            body: JSON.stringify({
                name: subSubCategoryName,
                category_id: categoryIds,
                sub_category_id: subCategoryId,
            }),
        })

        if (subSubCategoryPost.ok) {
            setMess('New SubSub Category Created')
            window.location.reload()
            // console.log('New Sub Sub Category Created')
        } else {
        }
    }

    return (
        <Layout>
            <Category
                setCategoryName={setCategoryName}
                setSubCategoryName={setSubCategoryName}
                setSubSubCategoryName={setSubSubCategoryName}
                setCategoryId={setCategoryId}
                setCategoryIds={setCategoryIds}
                setSubCategoryId={setSubCategoryId}
                categoryAll={categoryAll}
                subCategoryAll={subCategoryAll}
                handleCategory={handleCategory}
                handleSubCategory={handleSubCategory}
                handleSubSubCategory={handleSubSubCategory}
                mess={mess}
            />
        </Layout>
    )
}
