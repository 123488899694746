import React, { useState } from 'react'
import { LuPlus } from 'react-icons/lu'
import Product from './Product/Product'
import classes from './Selection.module.css'

const Selection = () => {
    const [inputList, setInputList] = useState([{ id: 1 }])

    const handleAdd = () => {
        setInputList([
            ...inputList,
            { id: inputList[inputList.length - 1] ? inputList[inputList.length - 1].id + 1 : 0 + 1 },
        ])
    }

    return (
        <div className={classes.container}>
            <div className={classes.whiteContainer}>
                <div className={classes.innerContainer}>
                    {inputList?.map((input) => {
                        return (
                            <Product key={input.id} id={input.id} setInputList={setInputList} inputList={inputList} />
                        )
                    })}
                    <div className={`${inputList[inputList.length - 1]?.title ? classes.add : classes.disabled}`}>
                        <button onClick={handleAdd} disabled={inputList[inputList.length - 1]?.title ? false : true}>
                            <LuPlus size={20} />
                            Add Product
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Selection
