import React, { useState } from 'react'
import classes from './Delivery.module.css'

const Delivery = () => {
    const handleSubmit = async (e) => {
        e.preventDefault()
    }
    const [delivery, setDelivery] = useState('regular')
    const handleDelivery = (e) => {
        setDelivery(e.target.value)
    }
    return (
        <div className={classes.container}>
            <form onSubmit={handleSubmit}>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px' }}>
                    <div className={classes.innerContainer}>
                        <div className={classes.half}>
                            <label className={classes.radio}>
                                <input
                                    type="radio"
                                    value="regular"
                                    name="delivery type"
                                    checked={delivery === 'regular'}
                                    onChange={handleDelivery}
                                />
                                <p>Regular Delivery</p>
                                <div>৳ 100</div>
                                <span>Estimated Delivery Time 28 Days</span>
                            </label>
                            <label className={classes.radio}>
                                <input
                                    type="radio"
                                    value="express"
                                    name="delivery type"
                                    checked={delivery === 'express'}
                                    onChange={handleDelivery}
                                />
                                <p>Express Delivery</p>
                                <div>৳ 250</div>
                                <span>Estimated Delivery Time 12 Days</span>
                            </label>
                            <label className={classes.radio}>
                                <input
                                    type="radio"
                                    value="custom"
                                    name="delivery type"
                                    checked={delivery === 'custom'}
                                    onChange={handleDelivery}
                                />
                                <p>Custom Delivery</p>
                                <span>Contact here - 01720000000</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className={classes.buttonContainer}>
                    <button type="submit">Submit</button>
                </div>
            </form>
        </div>
    )
}

export default Delivery
