import React from 'react'
import classes from './Profile.module.css'

export default function Profile() {
    return (
        <div className={classes.wrapper}>
            <p> Profile</p>
        </div>
    )
}
