import React, { useEffect, useState } from 'react'
import { Layout, RfqList } from '../components'
import { getRfq } from '../utils/functions'

export default function RfqListPage() {
    const [rfq, setRfq] = useState('')
    const [rfqCount, setRfqCount] = useState(0)
    const [check, setCheck] = useState(false)
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)

    useEffect(() => {
        // Function to fetch data
        const fetchData = () => {
            getRfq(skip, limit)
                .then((items) => {
                    setRfq(items[1])
                    setRfqCount(items[0].results)
                    setCheck(false)
                })
                .catch((error) => {
                    console.error('Error fetching data:', error)
                })
        }

        fetchData()
    }, [skip, limit, check])

    useEffect(() => {
        rfqCount !== 0 && setLimit(rfqCount)
    }, [rfqCount, check])

    return (
        <Layout>
            <RfqList rfq={rfq} rfqCount={rfqCount} />
        </Layout>
    )
}
