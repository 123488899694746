import React, { useContext, useEffect, useState } from 'react'
import { FaFile } from 'react-icons/fa'
import { MdFileDownload } from 'react-icons/md'
import { Auth } from '../../../../contexts/allContext'
import { fileNameFormatter } from '../../../../utils/fileNameFormatter'
import classes from './Document.module.css'

const Document = ({ documentLists }) => {
    const [link, setLink] = useState(null)
    const apiV1 = process.env.REACT_APP_API_URL_V1

    const { stateAuth } = useContext(Auth)
    useEffect(() => {
        if (link) {
            window.open(link, '_blank')
            setLink(null)
        }
    }, [link])
    const token = stateAuth.token
    const handleOnClick = (string) => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiV1}/temporary/url?service_name=loan&file_string=${string}`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                if (response.ok) {
                    setLink((prev) => {
                        prev = data?.temporary_url

                        return prev
                    })
                } else {
                    setLink(null)
                }
            } catch (error) {}
        }
        fetchData()
    }
    return (
        <div className={classes.container}>
            <div style={{ padding: '20px' }}>
                <div className={classes.innerContainer}>
                    <p>Trade License</p>
                    <div className={classes.upload}>
                        <div>
                            <FaFile size={16} /> {fileNameFormatter(documentLists?.trade_license_string || '')}
                        </div>
                        <button onClick={() => handleOnClick(documentLists?.trade_license_string || '')}>
                            <MdFileDownload size={16} />
                        </button>
                    </div>
                    <p>Incorporation Certificate</p>
                    <div className={classes.upload}>
                        <div>
                            <FaFile size={16} />{' '}
                            {fileNameFormatter(documentLists?.incorporation_certificate_string || '')}
                        </div>
                        <button onClick={() => handleOnClick(documentLists?.incorporation_certificate_string)}>
                            <MdFileDownload size={16} />
                        </button>
                    </div>
                    <p>Business TIN</p>
                    <div className={classes.upload}>
                        <div>
                            <FaFile size={16} /> {fileNameFormatter(documentLists?.business_tin_string || '')}
                        </div>
                        <button onClick={() => handleOnClick(documentLists?.business_tin_string)}>
                            <MdFileDownload size={16} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Document
