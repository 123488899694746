import React from 'react'
import { Layout } from '../components'
import CreateOrder from '../components/CreateOrder/CreateOrder'

const CreateOrderPage = () => {
    return (
        <Layout>
            <CreateOrder />
        </Layout>
    )
}

export default CreateOrderPage
