import React, { useEffect } from 'react'
import { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../../assets/Logo/logo.png'
import { Auth, UserInfo } from '../../../contexts/allContext'
import { apiV1 } from '../../../utils/api'
import classes from './LogIn.module.css'

export default function LogIn() {
    const [identifier, setIdentifier] = useState('')
    const [password, setPassword] = useState('')
    const { stateAuth, dispatchAuth } = useContext(Auth)
    const { stateUser } = useContext(UserInfo)
    const admin = stateUser.info
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()

        let logFetch = await fetch(`${apiV1}/login/in`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                identifier,
                password,
            }),
        })

        let log = await logFetch.json()

        if (logFetch.ok) {
            // console.log(log.access_token)
            dispatchAuth({ type: 'token', payload: log.access_token })
        } else {
        }
    }

    useEffect(() => {
        if (stateAuth.auth) {
            navigate('/dashboard')
        }
    }, [stateAuth, admin, navigate])

    return (
        <div className={classes.wrapper}>
            <Link to="/" className={classes.logo}></Link>
            <div className={classes.container} id="container">
                <div className={`${classes.formContainer} ${classes.signIn}`}>
                    <div className={classes.left}>
                        <div className={classes.leftContainer}></div>
                    </div>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className={classes.logoConainer}>
                            <img src={logo} alt="" />
                        </div>
                        <p>SIGN IN</p>
                        <input
                            placeholder="Phone or Email"
                            name="username"
                            type="text"
                            required
                            onChange={(e) => setIdentifier(e.target.value)}
                        />

                        <input
                            placeholder="Password"
                            name="password"
                            required
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Link to="#">Forgot your password?</Link>
                        <button type="submit">Sign In</button>
                    </form>
                </div>
            </div>
        </div>
    )
}
