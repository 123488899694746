import React from 'react'
import { useState, useEffect, useContext } from 'react'
import Select from 'react-select'
import { Auth, UserInfo } from '../../../contexts/allContext'
import state from '../../../utils/StateData.json'
import { apiV1 } from '../../../utils/api'
import classes from './Edit.module.css'

export default function Edit({ setEdit, userId, setSuccess, sellerData, Seller }) {
    // for user Information
    const { stateAuth, dispatchAuth } = useContext(Auth)
    const [alert, setAlert] = useState('')
    const [userName, setUserName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [pageName, setPageName] = useState('')
    const [pocName, setPocName] = useState('')
    const [pocNumber, setPocNumber] = useState('')
    const [address, setAddress] = useState('')
    const [about, setAbout] = useState('')
    const [category, setCategory] = useState('')
    const [designation, setDesignation] = useState('')
    const [city, setCity] = useState()

    // logo and banner
    const [logo, setLogo] = useState()
    const [banner, setBanner] = useState()

    //Compnay Info
    const [registerDate, setRegisterDate] = useState('')
    const [yearExport, setYearExport] = useState('')
    const [annualExport, setAnnualExport] = useState('')
    const [floorSpace, setFloorSpace] = useState('')
    const [productionMachine, setProductionMachine] = useState('')
    const [productionLine, setProductionLine] = useState('')
    const [annualOutput, setAnnualOutput] = useState('')
    const [supplyPartner, setSupplyPartner] = useState('')
    const [mainMarket, setMainmarket] = useState('')

    let token = stateAuth.token

    // for fetching basic info
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiV1}/users/${userId}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })

                const data = await response.json()

                if (response.ok) {
                    setUserName(data.name)
                    setEmail(data.email)
                    setPhone(data.phone)
                }
            } catch {}
        }
        fetchData()
    }, [userId])

    //   for fetching seller and company  Info
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiV1}/companies/${userId}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })

                const data = await response.json()

                if (response.ok) {
                    setPageName(data.Seller.page_name)
                    setAddress(data.Seller.address)
                    setCategory(data.Seller.category)
                    setDesignation(data.Seller.designation)
                    setAbout(data.Seller.about)
                    setPocName(data.Seller.poc_name)
                    setPocNumber(data.Seller.poc_phone)
                    setCity(data.Seller.city)
                    setRegisterDate(data.Company.registration_date)
                    setYearExport(data.Company.year_exporting)
                    setAnnualExport(data.Company.annual_export)
                    setFloorSpace(data.Company.floor_space)
                    setProductionMachine(data.Company.production_machine)
                    setProductionLine(data.Company.production_line)
                    setAnnualOutput(data.Company.annual_output)
                    setSupplyPartner(data.Company.supply_partner)
                    setMainmarket(data.Company.main_market)
                }
            } catch {}
        }
        fetchData()
    }, [userId])

    // for updating basic info
    const handleBasicInfo = async (e) => {
        e.preventDefault()

        let response = await fetch(`${apiV1}/users/${userId}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                name: userName,
                phone: phone,
                email: email,
            }),
        })

        if (response.ok) {
            setAlert('Basic Info Update Successful')

            setSuccess(true)

            setTimeout(() => {
                setAlert('')
                setSuccess((prev) => !prev)
            }, 3000)
        } else {
            setAlert('Something went wrong!')
            setTimeout(() => {
                setAlert('')
            }, 3000)
        }
    }

    // for updating Seller Info

    const handleSellerInfo = async (e) => {
        e.preventDefault()

        let response = await fetch(`${apiV1}/sellers/${userId}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                page_name: pageName,
                country: 'Bangladesh',
                city: city,
                address: address,
                category: category,
                about: about,
                poc_name: pocName,
                poc_phone: pocNumber,
                designation: designation,
            }),
        })

        if (response.ok) {
            setAlert('Seller Info Update Successful')
            setSuccess(true)

            setTimeout(() => {
                setAlert('')
                setSuccess((prev) => !prev)
            }, 3000)
        } else {
            setAlert('Something went wrong!')
            setTimeout(() => {
                setAlert('')
            }, 3000)
        }
    }

    // for updating logo

    const updateLogo = async (e) => {
        const logoData = new FormData()
        logoData.append('logo', logo)

        try {
            const response = await fetch(`${apiV1}/logos/${userId}`, {
                headers: {
                    Accept: 'application/json',
                    type: 'image/jpeg',
                },
                method: 'PUT',
                body: logoData,
            })

            if (response.ok) {
                setAlert('logo Updated successfully')
                setSuccess(true)

                setTimeout(() => {
                    setAlert('')
                    setSuccess((prev) => !prev)
                }, 3000)
            } else {
                setAlert('Something went wrong!')
                setTimeout(() => {
                    setAlert('')
                }, 3000)
            }
        } catch {
            alert('Please check your internet connection!')
        }
    }

    // for updating banner

    const updateBanner = async (e) => {
        const bannerData = new FormData()
        bannerData.append('banner', banner)

        try {
            const response = await fetch(`${apiV1}/banners/${userId}`, {
                headers: {
                    Accept: 'application/json',
                    type: 'image/jpeg',
                },
                method: 'PUT',
                body: bannerData,
            })

            if (response.ok) {
                setAlert('Banner Updated successfully')
                setSuccess(true)

                setTimeout(() => {
                    setAlert('')
                    setSuccess((prev) => !prev)
                }, 3000)
            } else {
                setAlert('Something went wrong!')
                setTimeout(() => {
                    setAlert('')
                }, 3000)
            }
        } catch {
            alert('Please check your internet connection!')
        }
    }

    // for updating company Information

    const handleCompanyInfo = async (e) => {
        e.preventDefault()

        let response = await fetch(`${apiV1}/companies/${userId}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                registration_date: registerDate,
                year_exporting: yearExport,
                annual_export: annualExport,
                floor_space: floorSpace,
                production_machine: productionMachine,
                production_line: productionLine,
                annual_output: annualOutput,
                supply_partner: supplyPartner,
                main_market: mainMarket,
            }),
        })

        if (response.ok) {
            setAlert('Company  Info Update Successful')
            setSuccess(true)

            setTimeout(() => {
                setAlert('')
                setSuccess((prev) => !prev)
            }, 3000)
        } else {
            setAlert('Something went wrong!')
            setTimeout(() => {
                setAlert('')
            }, 3000)
        }
    }

    return (
        <div className={classes.containerForm}>
            <div className={classes.overlay}></div>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setEdit(false)}>
                    &times;
                </div>

                <p>Update Seller</p>
                <div className={classes.innerWrap}>
                    <div className={classes.container}>
                        <ul>
                            <li>
                                <input type="checkbox" id="listItem2" />
                                <label htmlFor="listItem2" className={classes.labelMain}>
                                    Basic Info
                                </label>
                                <ul>
                                    <div className={classes.innerContainer}>
                                        <div className={classes.collab}>
                                            <label>
                                                Name
                                                <input
                                                    type="text"
                                                    value={userName}
                                                    name="name"
                                                    required
                                                    onChange={(e) => setUserName(e.target.value)}
                                                />
                                            </label>

                                            <div className={classes.formGrid}>
                                                <label>
                                                    Phone
                                                    <input
                                                        value={phone}
                                                        type="tel"
                                                        name="phone"
                                                        minLength={14}
                                                        maxLength={14}
                                                        pattern="[0][1][0-9]{9}"
                                                        required
                                                        onChange={(e) => setPhone(e.target.value)}
                                                    />
                                                </label>
                                                <label>
                                                    Email
                                                    <input
                                                        type="email"
                                                        value={email}
                                                        name="email"
                                                        required
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <button className={classes.buttonSubmit} onClick={(e) => handleBasicInfo(e)}>
                                            UPDATE BASIC INFO
                                        </button>
                                    </div>
                                </ul>
                            </li>

                            <li>
                                <input type="checkbox" id="listItem3" />
                                <label htmlFor="listItem3" className={classes.labelMain}>
                                    Seller Info
                                </label>
                                <ul>
                                    <div className={classes.innerContainer}>
                                        <div className={classes.collab}>
                                            <div className={classes.formGrid}>
                                                <label>
                                                    Company Name
                                                    <input
                                                        type="text"
                                                        name="page_name"
                                                        required
                                                        value={pageName}
                                                        onChange={(e) => setPageName(e.target.value)}
                                                    />
                                                </label>
                                                <label>
                                                    Select Category
                                                    <select
                                                        className={classes.select}
                                                        name="category"
                                                        onChange={(e) => setCategory(e.target.value)}>
                                                        <option selected disabled hidden>
                                                            {category}
                                                        </option>
                                                        <option>RMG & Accessories</option>
                                                        <option>Leather & Leather Product</option>
                                                        <option>Jute & Jute Product</option>
                                                        <option>Agro & Agro Based</option>
                                                        <option>Home Textilies </option>
                                                        <option>Handicraft </option>
                                                        <option>Food & Beverages</option>
                                                        <option>Furniture & Furnishing </option>
                                                        <option>Building Materials </option>
                                                        <option>Books, Stationary</option>
                                                        <option>Consumer Electronics </option>
                                                        <option>Fashion , Beauty </option>
                                                        <option> Packaging & Paper</option>
                                                        <option> Hospital & Medical </option>
                                                    </select>
                                                </label>

                                                <label>
                                                    Select District
                                                    <select
                                                        className={classes.select}
                                                        name="city"
                                                        value={city}
                                                        onChange={(e) => setCity(e.target.value)}>
                                                        {state.map((geState, index) => (
                                                            <option value={geState.state_name} key={index}>
                                                                {geState.state_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </label>
                                                <label>
                                                    Address
                                                    <input
                                                        type="text"
                                                        name="address"
                                                        required
                                                        value={address}
                                                        onChange={(e) => setAddress(e.target.value)}
                                                    />
                                                </label>

                                                <label>
                                                    Contact Person Name
                                                    <input
                                                        type="text"
                                                        name="poc_name"
                                                        required
                                                        value={pocName}
                                                        onChange={(e) => setPocName(e.target.value)}
                                                    />
                                                </label>
                                                <label>
                                                    Contact Person Number
                                                    <input
                                                        type="tel"
                                                        name="poc_phone"
                                                        required
                                                        value={pocNumber}
                                                        onChange={(e) => setPocNumber(e.target.value)}
                                                    />
                                                </label>

                                                <label>
                                                    Select Designation
                                                    <select
                                                        className={classes.select}
                                                        name="designation"
                                                        onChange={(e) => setDesignation(e.target.value)}>
                                                        <option selected disabled hidden>
                                                            {designation}
                                                        </option>
                                                        <option value="Manager">Manager</option>
                                                        <option value="Owner">Owner</option>
                                                        <option value="Employee">Employee</option>
                                                    </select>
                                                </label>
                                                <label>
                                                    About Company
                                                    <input
                                                        type="text"
                                                        name="company"
                                                        required
                                                        value={about}
                                                        onChange={(e) => setAbout(e.target.value)}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <button className={classes.buttonSubmit} onClick={(e) => handleSellerInfo(e)}>
                                            UPDATE SELLER INFO
                                        </button>
                                    </div>
                                </ul>
                            </li>

                            <li>
                                <input type="checkbox" id="listItem4" />
                                <label htmlFor="listItem4" className={classes.labelMain}>
                                    Logo and Banner
                                </label>
                                <ul>
                                    <div className={classes.innerContainer}>
                                        <div className={classes.collab}>
                                            <label>
                                                Select Logo
                                                <input
                                                    type="file"
                                                    accept="image"
                                                    required
                                                    onChange={(e) => setLogo(e.target.files[0])}
                                                />
                                            </label>
                                        </div>

                                        <button className={classes.buttonSubmit} onClick={(e) => updateLogo(e)}>
                                            Update Logo
                                        </button>

                                        <div style={{ marginTop: '40px' }} className={classes.collab}>
                                            <label>
                                                Select Banner
                                                <input
                                                    type="file"
                                                    accept="image"
                                                    required
                                                    onChange={(e) => setBanner(e.target.files[0])}
                                                />
                                            </label>
                                        </div>
                                        <button className={classes.buttonSubmit} onClick={(e) => updateBanner(e)}>
                                            Update Banner
                                        </button>
                                    </div>
                                </ul>
                            </li>

                            <li>
                                <input type="checkbox" id="listItem5" />
                                <label htmlFor="listItem5" className={classes.labelMain}>
                                    Company Info
                                </label>
                                <ul>
                                    <div className={classes.innerContainer}>
                                        <div className={classes.collab}>
                                            <div className={classes.formGrid}>
                                                <label>
                                                    Registration Date
                                                    <input
                                                        type="date"
                                                        name="registration_date"
                                                        required
                                                        value={registerDate === 'string' ? '' : registerDate}
                                                        onChange={(e) => setRegisterDate(e.target.value)}
                                                    />
                                                </label>

                                                <label>
                                                    Year Exporting
                                                    <input
                                                        type="text"
                                                        name="year_exporting"
                                                        required
                                                        value={yearExport === 'string' ? '' : yearExport}
                                                        onChange={(e) => setYearExport(e.target.value)}
                                                    />
                                                </label>

                                                <label>
                                                    Annual Export
                                                    <input
                                                        type="text"
                                                        name="annual_export"
                                                        required
                                                        value={annualExport === 'string' ? '' : annualExport}
                                                        onChange={(e) => setAnnualExport(e.target.value)}
                                                    />
                                                </label>

                                                <label>
                                                    Floor Space
                                                    <input
                                                        type="text"
                                                        name="floor_space"
                                                        required
                                                        value={floorSpace === 'string' ? '' : floorSpace}
                                                        onChange={(e) => setFloorSpace(e.target.value)}
                                                    />
                                                </label>

                                                <label>
                                                    Production Machine
                                                    <input
                                                        type="text"
                                                        name="production_machine"
                                                        required
                                                        value={productionMachine === 'string' ? '' : productionMachine}
                                                        onChange={(e) => setProductionMachine(e.target.value)}
                                                    />
                                                </label>

                                                <label>
                                                    Production Line
                                                    <input
                                                        type="text"
                                                        name="production_line"
                                                        required
                                                        value={productionLine === 'string' ? '' : productionLine}
                                                        onChange={(e) => setProductionLine(e.target.value)}
                                                    />
                                                </label>

                                                <label>
                                                    Annual Output
                                                    <input
                                                        type="text"
                                                        name="annual_output"
                                                        required
                                                        value={annualOutput === 'string' ? '' : annualOutput}
                                                        onChange={(e) => setAnnualOutput(e.target.value)}
                                                    />
                                                </label>

                                                <label>
                                                    Supply Partner
                                                    <input
                                                        type="text"
                                                        name="supply_partner"
                                                        required
                                                        value={supplyPartner === 'string' ? '' : supplyPartner}
                                                        onChange={(e) => setSupplyPartner(e.target.value)}
                                                    />
                                                </label>

                                                <label>
                                                    Main Market
                                                    <input
                                                        type="text"
                                                        name="main_market"
                                                        required
                                                        value={mainMarket === 'string' ? '' : mainMarket}
                                                        onChange={(e) => setMainmarket(e.target.value)}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <button className={classes.buttonSubmit} onClick={(e) => handleCompanyInfo(e)}>
                                            UPDATE COMPANY INFO
                                        </button>
                                    </div>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <span className={classes.message}>{alert}</span>
            </div>
        </div>
    )
}
