import React from 'react'
import { useState, useEffect } from 'react'
import { Layout, ActiveSellers } from '../components'
import { getCompany } from '../utils/functions'
import useDebounce from '../utils/useDebounce'

export default function SellerActiveListPage() {
    const [allSellers, setAllSellers] = useState([])
    const [sellers, setSellers] = useState([])
    const [pageSkip, setPageSkip] = useState(0)
    const [limit, setLimit] = useState(100)
    const [pageAll, setPageAll] = useState(0)
    const [check, setCheck] = useState(false)
    const [userId, setUserId] = useState('')

    const [sellerData, setSellerData] = useState()
    const [companyName, setCompanyName] = useState('')
    const debouncedCompanyName = useDebounce(companyName, 800)
    const [phone, setPhone] = useState('')
    const debouncedPhone = useDebounce(phone, 800)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    useEffect(() => {
        // Function to fetch data
        const fetchData = () => {
            getCompany(debouncedCompanyName, pageSkip, 10, startDate, endDate)
                .then((items) => {
                    setSellers(items[1])
                    setPageAll(items[0].results)
                    setCheck(false)
                })
                .catch((error) => {
                    console.error('Error fetching data:', error)
                })
        }

        fetchData()
    }, [debouncedCompanyName, pageSkip, check, startDate, endDate])

    useEffect(() => {
        // Function to fetch data
        const fetchData = () => {
            getCompany('', 0, limit)
                .then((items) => {
                    setAllSellers(items[1])
                    setLimit(items[0].results)
                    setCheck(false)
                })
                .catch((error) => {
                    console.error('Error fetching data:', error)
                })
        }

        fetchData()
    }, [debouncedCompanyName, pageSkip, check, limit])

    useEffect(() => {
        setLimit(limit)
    }, [limit])

    // specefic sellers
    return (
        <Layout>
            <ActiveSellers
                companyName={companyName}
                setCompanyName={setCompanyName}
                sellers={sellers}
                pageAll={pageAll}
                pageSkip={pageSkip}
                setPageSkip={setPageSkip}
                check={check}
                setCheck={setCheck}
                setUserId={setUserId}
                userId={userId}
                sellerData={sellerData}
                setSellerData={setSellerData}
                allSellers={allSellers}
                phone={phone}
                setPhone={setPhone}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
            />
        </Layout>
    )
}

// const img_string = props.sellerData ? props.sellerData[1][0].ImgLogo.image_string : ''
// const img_data = `${apiV1}/images/logo/${img_string}`
// console.log(img_data)
