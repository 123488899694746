import React from 'react'
import classes from './Confirm.module.css'

export default function Confirm(props) {
    return (
        <div className={classes.wrapper}>
            <div>
                <p>{props.message}</p>
            </div>
            <div>
                <button
                    onClick={() => {
                        props.setActivePopUp(false)
                        props.handleDelete()
                    }}>
                    Yes
                </button>
                <button
                    onClick={() => {
                        props.setActivePopUp(false)
                    }}>
                    Cancel
                </button>
            </div>{' '}
        </div>
    )
}
