import React from 'react'
import { FaLongArrowAltRight } from 'react-icons/fa'
import classes from './Filter.module.css'

const Filter = ({ setCustomerName, setPhone, setBankName, setLoan, setDateStart, setDateEnd, amount, setAmount }) => {
    const handleOnchangeMin = (e) => {
        setAmount([parseInt(e.target.value), amount[1]])
    }
    const handleOnchangeMax = (e) => {
        setAmount([amount[0], parseInt(e.target.value)])
    }
    return (
        <div className={classes.container}>
            <input type="text" placeholder="Enter Customer Name..." onChange={(e) => setCustomerName(e.target.value)} />
            <input
                type="text"
                placeholder="Enter Customer Phone Number..."
                onChange={(e) => setPhone(e.target.value)}
            />
            <input type="text" placeholder="Enter Bank Name..." onChange={(e) => setBankName(e.target.value)} />
            <input type="text" placeholder="Enter Loan Name..." onChange={(e) => setLoan(e.target.value)} />
            <div className={classes.dateBlock}>
                <input type="date" onChange={(e) => setDateStart(e.target.value)} />
                <FaLongArrowAltRight size={18} />
                <input type="date" onChange={(e) => setDateEnd(e.target.value)} />
            </div>
            <div className={classes.sliderContainer}>
                <input type="number" min={0} value={amount[0]} onChange={handleOnchangeMin} />
                -
                <input type="number" min={0} value={amount[1]} onChange={handleOnchangeMax} />
                <div>
                    {amount[0]}৳ - {amount[1]}৳
                </div>
            </div>
        </div>
    )
}

export default Filter
