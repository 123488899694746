import React from 'react'
import classes from './Shipment.module.css'

const Shipment = () => {
    return (
        <div className={classes.container}>
            <div className={classes.whiteContainer}>
                <div className={classes.innerContainer}>
                    <div className={classes.permanent}>
                        <div>
                            <button type="button">Same as Permanent Address</button>
                        </div>
                    </div>
                    <div className={classes.half}>
                        <div>
                            <p>Full Name</p>
                            <input type="text" />
                        </div>
                        <div>
                            <p>Country Name</p>
                            <input type="text" />
                        </div>
                    </div>
                    <div className={classes.half}>
                        <div>
                            <p>District</p>
                            <input type="text" />
                        </div>
                        <div>
                            <p>Sub-District</p>
                            <input type="text" />
                        </div>
                    </div>
                    <div className={classes.half}>
                        <div>
                            <p>Division</p>
                            <input type="text" />
                        </div>
                        <div>
                            <p>Phone</p>
                            <input type="number" />
                        </div>
                    </div>
                    <p>Address</p>
                    <textarea type="text" />
                </div>
            </div>
        </div>
    )
}

export default Shipment
