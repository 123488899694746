import React, { useContext, useEffect, useState } from 'react'
import { FaThList } from 'react-icons/fa'
import { FaCircleCheck } from 'react-icons/fa6'
import { ImCross } from 'react-icons/im'
import { IoAddCircle } from 'react-icons/io5'
import { MdArrowDropDown } from 'react-icons/md'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../../assets/Logo/logo.png'
import { Category, Order, Seller } from '../../../contexts/allContext'
import classes from './SideBarOne.module.css'

export default function SidebarOne(props) {
    const location = useLocation()
    const { stateSeller, dispatchSeller } = useContext(Seller)
    const { stateOrder, dispatchOrder } = useContext(Order)
    const { stateCategory, dispatchCategory } = useContext(Category)

    const [clickLink, setClickLink] = useState(false)
    const [clickLinkCategory, setClickLinkCategory] = useState(false)
    const [clickLinkOrder, setClickLinkOrder] = useState(false)

    useEffect(() => {
        if (
            location.pathname === '/active-sellers' ||
            location.pathname === '/Inactive-sellers' ||
            location.pathname === '/create-seller'
        ) {
            dispatchSeller({ type: 'open' })
            setClickLink(true)
        }
    }, [location, dispatchSeller])
    useEffect(() => {
        if (location.pathname === '/create-category' || location.pathname === '/category-list') {
            dispatchCategory({ type: 'open' })
            setClickLinkCategory(true)
        }
    }, [location, dispatchCategory])
    useEffect(() => {
        if (location.pathname === '/create-order' || location.pathname === '/order-list') {
            dispatchOrder({ type: 'open' })
            setClickLinkOrder(true)
        }
    }, [location, dispatchOrder])
    const handleSeller = () => {
        if (stateSeller.status === true) {
            dispatchSeller({ type: 'close' })
        } else {
            dispatchSeller({ type: 'open' })
        }
    }
    const handleCategory = () => {
        if (stateCategory.status === true) {
            dispatchCategory({ type: 'close' })
        } else {
            dispatchCategory({ type: 'open' })
        }
    }
    const handleOrder = () => {
        if (stateOrder.status === true) {
            dispatchOrder({ type: 'close' })
        } else {
            dispatchOrder({ type: 'open' })
        }
    }
    const handleClickLink = () => {
        !clickLink && setClickLink(true)
    }

    const handleClickLinkC = () => {
        !clickLinkCategory && setClickLinkCategory(true)
    }
    const handleClickLinkO = () => {
        !clickLinkOrder && setClickLinkOrder(true)
    }

    return (
        <div>
            <div className={classes.sidebar}>
                <div>
                    <img src={logo} alt="" />
                </div>
                <div className={classes.listItem}>
                    {props.sidebarItems.map((item, i) => (
                        <li key={i}>
                            {item.path === '/seller-list' ? (
                                <>
                                    <button
                                        className={clickLink ? classes.btnActive : classes.btnInactive}
                                        onClick={() => handleSeller()}>
                                        <div className={classes.iconDiv}>{item.icon}</div>
                                        <div>
                                            <div className={classes.productText}>{item.text}</div>
                                            <MdArrowDropDown
                                                className={
                                                    stateSeller.status
                                                        ? `${classes.iconDropDown} ${classes.open} `
                                                        : classes.iconDropDown
                                                }
                                                style={{ color: `${clickLink ? 'white' : 'black'}` }}
                                            />
                                        </div>
                                    </button>
                                    <div
                                        className={
                                            stateSeller.status
                                                ? `${classes.dropDown} ${classes.open} `
                                                : classes.dropDown
                                        }>
                                        <Link
                                            to="/active-sellers"
                                            onClick={(e) => {
                                                handleClickLink(e)
                                                dispatchSeller({ type: 'open' })
                                            }}
                                            className={`${
                                                location.pathname === '/active-sellers'
                                                    ? classes.activeSubNav
                                                    : classes.inactiveSubNav
                                            }`}>
                                            <FaCircleCheck /> Active Seller List
                                        </Link>
                                        <Link
                                            to="/Inactive-sellers"
                                            onClick={(e) => {
                                                handleClickLink(e)
                                                dispatchSeller({ type: 'open' })
                                            }}
                                            className={`${
                                                location.pathname === '/Inactive-sellers'
                                                    ? classes.activeSubNav
                                                    : classes.inactiveSubNav
                                            }`}>
                                            <ImCross /> Inactive Seller List
                                        </Link>

                                        <Link
                                            to="/create-seller"
                                            onClick={(e) => {
                                                handleClickLink(e)
                                                dispatchSeller({ type: 'open' })
                                            }}
                                            className={`${
                                                location.pathname === '/create-seller'
                                                    ? classes.activeSubNav
                                                    : classes.inactiveSubNav
                                            }`}>
                                            <IoAddCircle /> Create Seller
                                        </Link>
                                    </div>
                                </>
                            ) : item.path === '/category' ? (
                                <>
                                    <button
                                        className={clickLinkCategory ? classes.btnActive : classes.btnInactive}
                                        onClick={() => handleCategory()}>
                                        <div className={classes.iconDiv}>{item.icon}</div>{' '}
                                        <div>
                                            <div className={classes.productText}>{item.text}</div>
                                            <MdArrowDropDown
                                                className={
                                                    stateCategory.status
                                                        ? `${classes.iconDropDown} ${classes.open}`
                                                        : classes.iconDropDown
                                                }
                                                style={{ color: `${clickLinkCategory ? 'white' : 'black'}` }}
                                            />
                                        </div>
                                    </button>
                                    <div
                                        className={
                                            stateCategory.status
                                                ? `${classes.dropDown} ${classes.openCategory}`
                                                : classes.dropDown
                                        }>
                                        <Link
                                            to="/create-category"
                                            onClick={(e) => {
                                                handleClickLinkC(e)
                                                dispatchCategory({ type: 'open' })
                                            }}
                                            className={`${
                                                location.pathname === '/create-category'
                                                    ? classes.activeSubNav
                                                    : classes.inactiveSubNav
                                            }`}>
                                            <IoAddCircle /> Create Category
                                        </Link>
                                        <Link
                                            to="/category-list"
                                            onClick={(e) => {
                                                handleClickLinkC(e)
                                                dispatchCategory({ type: 'open' })
                                            }}
                                            className={`${
                                                location.pathname === '/category-list'
                                                    ? classes.activeSubNav
                                                    : classes.inactiveSubNav
                                            }`}>
                                            <FaThList /> Category List/Edit
                                        </Link>
                                    </div>
                                </>
                            ) : item.path === '/orders' ? (
                                <>
                                    <button
                                        className={clickLinkOrder ? classes.btnActive : classes.btnInactive}
                                        onClick={() => handleOrder()}>
                                        <div className={classes.iconDiv}>{item.icon}</div>{' '}
                                        <div>
                                            <div className={classes.productText}>{item.text}</div>
                                            <MdArrowDropDown
                                                className={
                                                    stateOrder.status
                                                        ? `${classes.iconDropDown} ${classes.open}`
                                                        : classes.iconDropDown
                                                }
                                                style={{ color: `${clickLinkOrder ? 'white' : 'black'}` }}
                                            />
                                        </div>
                                    </button>
                                    <div
                                        className={
                                            stateOrder.status
                                                ? `${classes.dropDown} ${classes.openCategory}`
                                                : classes.dropDown
                                        }>
                                        <Link
                                            to="/create-order"
                                            onClick={(e) => {
                                                handleClickLinkO(e)
                                                dispatchOrder({ type: 'open' })
                                            }}
                                            className={`${
                                                location.pathname === '/create-order'
                                                    ? classes.activeSubNav
                                                    : classes.inactiveSubNav
                                            }`}>
                                            <IoAddCircle /> Create Order
                                        </Link>
                                        <Link
                                            to="/order-list"
                                            onClick={(e) => {
                                                handleClickLinkO(e)
                                                dispatchOrder({ type: 'open' })
                                            }}
                                            className={`${
                                                location.pathname === '/order-list'
                                                    ? classes.activeSubNav
                                                    : classes.inactiveSubNav
                                            }`}>
                                            <FaThList /> Order List/Edit
                                        </Link>
                                    </div>
                                </>
                            ) : (
                                <Link to={item.path}>
                                    <div
                                        className={location.pathname === item.path ? classes.active : classes.inactive}>
                                        <div className={classes.iconDiv}>{item.icon}</div> <div>{item.text}</div>
                                    </div>
                                </Link>
                            )}
                        </li>
                    ))}
                </div>
            </div>
        </div>
    )
}
