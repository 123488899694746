//get category production
// import axios from 'axios'
import { apiV1 } from '../../src/utils/api'

export async function getSubAndSubsubcategoryByCategoryId(id) {
    try {
        const response = await fetch(`${apiV1}/categories/single/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data
        }
    } catch {}
}

export async function getSubcategoryByCategoryId(id) {
    try {
        const response = await fetch(`${apiV1}/categories/sub_category/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data
        }
    } catch {}
}

export async function getSubsubcategoryBySubcategoryId(id) {
    try {
        const response = await fetch(`${apiV1}/categories/sub_sub_category/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data
        }
    } catch {}
}

export async function getProductsByCategory(id) {
    try {
        const response = await fetch(`${apiV1}/products/category/${id}?skip=0&limit=100`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data[1]
        }
    } catch {}
}

export async function getProductsBySubcategory(id) {
    try {
        const response = await fetch(`${apiV1}/products/sub_category/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data[1]
        }
    } catch {}
}

export async function getProductsBySubsubcategory(id) {
    try {
        const response = await fetch(`${apiV1}/products/sub_sub_category/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data[1]
        }
    } catch {}
}

export async function getCompany() {
    try {
        const response = await fetch(`${apiV1}/companies/?skip=0&limit=10`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data[1]
        }
    } catch {}
}

export async function getCategoryAllDetails() {
    try {
        const response = await fetch(`${apiV1}/categories/all`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data
        }
    } catch {}
}

export async function getCategoryAll() {
    try {
        const response = await fetch(`${apiV1}/categories/`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()
        const categoryOptions = []

        if (response.ok) {
            await data.map((e) => categoryOptions.push({ id: e.id, name: e.name, label: e.name, value: e.name }))
            return categoryOptions
        }
    } catch {}
}

export async function getCategoryProducts(categoryId) {
    try {
        const response = await fetch(`${apiV1}/products/category/${categoryId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data[1]
        }
    } catch {}
}

export async function getProducts(title, category, skip, limit) {
    try {
        const response = await fetch(
            `${apiV1}/products/?title=${title}&category=${category}&skip=${skip}&limit=${limit}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${token}`,
                },
            }
        )

        const data = await response.json()

        if (response.ok) {
            return [data[0], data[1]]
        }
    } catch {}
}

export async function getProductsByUserId(userId) {
    try {
        const response = await fetch(`${apiV1}/products/seller/${userId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${token}`,
            },
        })
        // const response = await axios.get(`${apiV1}/products/category/${categoryId}`)
        // const data = await response.data

        const data = await response.json()

        if (response.ok) {
            return [data[0], data[1]]
        }
    } catch {}
}

// ---------------------------------------------- Category ---------------------------------------------------

export async function getCategory() {
    try {
        const response = await fetch(`${apiV1}/categories/`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data
        }
    } catch {}
}

export async function getSubCategory() {
    try {
        const response = await fetch(`${apiV1}/categories/sub_category`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data
        }
    } catch {}
}

export async function getSubSubCategory() {
    try {
        const response = await fetch(`${apiV1}/categories/sub_sub_category`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data
        }
    } catch {}
}
