import React, { useState } from 'react'
import { LuPlus } from 'react-icons/lu'
import Product from './Product/Product'
import classes from './Selection.module.css'

const Selection = () => {
    const [inputList, setInputList] = useState([{ id: 1 }])
    const handleSubmit = async (e) => {
        e.preventDefault()
    }
    const handleAdd = () => {
        setInputList([
            ...inputList,
            { id: inputList[inputList.length - 1] ? inputList[inputList.length - 1].id + 1 : 0 + 1 },
        ])
    }
    return (
        <div className={classes.container}>
            <form onSubmit={handleSubmit}>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px' }}>
                    <div className={classes.innerContainer}>
                        {inputList?.map((input) => {
                            return (
                                <Product
                                    key={input.id}
                                    id={input.id}
                                    setInputList={setInputList}
                                    inputList={inputList}
                                />
                            )
                        })}
                    </div>
                    <div className={`${inputList[inputList.length - 1]?.title ? classes.add : classes.disabled}`}>
                        <button onClick={handleAdd} disabled={inputList[inputList.length - 1]?.title ? false : true}>
                            <LuPlus size={20} />
                            Add Product
                        </button>
                    </div>
                </div>
                <div className={classes.buttonContainer}>
                    <button type="submit">Submit</button>
                </div>
            </form>
        </div>
    )
}

export default Selection
