import { CategoryDefStorage, CategoryStateStorage, CategoryGetStorage } from '../storage/storage'

export const categoryState = CategoryDefStorage('category', { status: false })
export const categoryReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            CategoryStateStorage('category', { status: true })
            return JSON.parse(CategoryGetStorage('category'))
        case 'close':
            CategoryStateStorage('category', { status: false })
            return JSON.parse(CategoryGetStorage('category'))
        default:
            return state
    }
}
