import React from 'react'
import { useState } from 'react'
import { CreateSeller, Layout } from '../components'

export default function CreateSellerPage() {
    const [firstName, setFirstName] = useState()
    const [secondName, setSecondName] = useState()
    const [phone, setPhone] = useState()
    const [email, setEmail] = useState()
    const [confPass, setconfPass] = useState()
    const [pass, setPass] = useState()

    // Company Info
    const [companyName, setCompanyName] = useState()
    const [countryName, setCountryName] = useState('Bangladesh')
    const [stateName, setStateName] = useState('')
    const [address, setAddress] = useState('')
    const [industry, setIndustry] = useState('')
    const [about, setAbout] = useState('')
    const [pocName, setPocName] = useState('')
    const [pocNumber, setPocNumber] = useState()
    const [designation, setDesignation] = useState()
    const [popUp, setpopUp] = useState(false)
    const [imageFile, setImageFile] = useState(null)
    const [imageFile2, setImageFile2] = useState(null)

    const handleSubmit = async (e) => {
        // e.preventDefault()
        // const imgData = new FormData()
        // imgData.append('logo', imageFile)
        // imgData.append('banner', imageFile2)
        // if (imageFile && imageFile2) {
        //     let registerId = await fetch(
        //         `${apiV1}/sellers/?page_name=${companyName}&country=${countryName}&city=${stateName}&address=${address}&category=${industry}&about=${about}&poc_name=${pocName}&poc_phone=${pocNumber}&designation=${designation}&name=${firstName}&phone=${phone}&email=${email}&password=${pass}`,
        //         {
        //             headers: {
        //                 Accept: 'application/json',
        //                 type: 'image/jpeg',
        //             },
        //             method: 'POST',
        //             body: imgData,
        //         }
        //     )
        //     let info = await registerId.json()
        //     if (registerId.ok) {
        //         setpopUp(true)
        //         setSuccess(true)
        //         setMessage('Registraion Successful.Plase Upload Your Product')
        //         localStorage.setItem('userId', JSON.stringify(info.user_id))
        //     } else {
        //         setMessage(info.context)
        //         setpopUp(true)
        //     }
        // } else {
        //     setAlertPreview('Please makesure to upload logo and banner!')
        //     setAlertTime(true)
        //     setTimeout(function () {
        //         setAlertTime(false)
        //     }, 3000)
        // }
    }

    return (
        <Layout>
            <CreateSeller
                setFirstName={setFirstName}
                setSecondName={setSecondName}
                setPhone={setPhone}
                setEmail={setEmail}
                setPass={setPass}
                setconfPass={setconfPass}
                firstName={firstName}
                secondName={secondName}
                pass={pass}
                email={email}
                countryName={countryName}
                confPass={confPass}
                phone={phone}
                setCompanyName={setCompanyName}
                setCountryName={setCountryName}
                setStateName={setStateName}
                setAddress={setAddress}
                setIndustry={setIndustry}
                setAbout={setAbout}
                setPocName={setPocName}
                setPocNumber={setPocNumber}
                setDesignation={setDesignation}
                popUp={popUp}
                setpopUp={setpopUp}
                setImageFile={setImageFile}
                setImageFile2={setImageFile2}
                companyName={companyName}
                stateName={stateName}
                address={address}
                industry={industry}
                about={about}
                pocName={pocName}
                pocNumber={pocNumber}
                designation={designation}
                imageFile={imageFile}
                imageFile2={imageFile2}
                handleSubmit={handleSubmit}
            />
        </Layout>
    )
}
