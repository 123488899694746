import React, { useState } from 'react'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import classes from './Payment.module.css'

const Payment = () => {
    const [paymentType, setPaymentType] = useState('full')
    const [paymentMethod, setPaymentMethod] = useState('bank')
    const [installment, setInstallment] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
    }
    const handleTypeChange = (e) => {
        setPaymentType(e.target.value)
    }
    const handlePayMethod = (e) => {
        setPaymentMethod(e.target.value)
    }
    const handleInstallment = (e) => {
        setInstallment(e.target.value)
    }

    return (
        <div className={classes.container}>
            <form onSubmit={handleSubmit}>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px' }}>
                    <div className={classes.innerContainer}>
                        <div className={classes.paymentMethod}>Payment Type</div>
                        <div className={classes.half}>
                            <label className={classes.radio}>
                                <input
                                    type="radio"
                                    value="full"
                                    name="payment type"
                                    checked={paymentType === 'full'}
                                    onChange={handleTypeChange}
                                />
                                <p>Full Payment</p>
                            </label>
                            <label className={classes.radio}>
                                <input
                                    type="radio"
                                    value="partial"
                                    name="payment type"
                                    checked={paymentType === 'partial'}
                                    onChange={handleTypeChange}
                                />
                                <p>Partial Payment</p>
                            </label>
                        </div>
                        {paymentType === 'full' && (
                            <div>
                                <div className={classes.paymentMethod}>Payment Method</div>
                                <div className={classes.half}>
                                    <div className={classes.radio}>
                                        <label className={classes.bank}>
                                            <input
                                                type="radio"
                                                value="bank"
                                                name="payment method"
                                                checked={paymentMethod === 'bank'}
                                                onChange={handlePayMethod}
                                            />
                                            <p>Bank Transfer</p>
                                        </label>
                                        {paymentMethod === 'bank' && (
                                            <div className={classes.bankDetails}>
                                                <hr className={classes.hr} />
                                                <h2>Payment Details</h2>
                                                <div className={classes.bankInfo}>
                                                    <div className={classes.infoField}>
                                                        <p>Bank Name</p>
                                                        <div>The City Bank Limited</div>
                                                    </div>
                                                    <div className={classes.infoField}>
                                                        <p>A/C Name</p>
                                                        <div>D IMPACT VENTURES BANGLADESH LTD.</div>
                                                    </div>
                                                    <div className={classes.infoField}>
                                                        <p>A/C NO</p>
                                                        <div>1253213484001</div>
                                                    </div>
                                                    <div className={classes.infoField}>
                                                        <p>Branch</p>
                                                        <div>Banani</div>
                                                    </div>
                                                    <div className={classes.infoField}>
                                                        <p>Branch Code</p>
                                                        <div>043</div>
                                                    </div>
                                                    <div className={classes.infoField}>
                                                        <p>Routing NO</p>
                                                        <div>225260438</div>
                                                    </div>
                                                </div>
                                                <p>After sending money please fillup the form below</p>
                                                <div className={classes.bankDetails}>
                                                    <div className={classes.payment}>
                                                        <p>Bank Name</p>
                                                        <input type="text" required />
                                                    </div>
                                                    <div className={classes.payment}>
                                                        <p>A/C NO</p>
                                                        <input type="text" required />
                                                    </div>
                                                    <div className={classes.payment}>
                                                        <p>Amount</p>
                                                        <input type="text" required />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className={classes.radio}>
                                        <label className={classes.bank}>
                                            <input
                                                type="radio"
                                                value="online"
                                                name="payment method"
                                                checked={paymentMethod === 'online'}
                                                onChange={handlePayMethod}
                                            />
                                            <p>Online Money Transfer</p>
                                        </label>
                                        {paymentMethod === 'online' && (
                                            <div className={classes.bankDetails}>
                                                <hr className={classes.hr} />
                                                <h2>Payment Details</h2>
                                                <div className={classes.bankInfo}>
                                                    <div className={classes.infoField}>
                                                        <p>Bkash Number</p>
                                                        <div>01727611963</div>
                                                    </div>
                                                </div>
                                                <div className={classes.payment}>
                                                    <p>Sender Phone Number</p>
                                                    <input type="text" required />
                                                </div>
                                                <div className={classes.payment}>
                                                    <p>Transaction ID</p>
                                                    <input type="text" required />
                                                </div>
                                                <div className={classes.payment}>
                                                    <p>Amount</p>
                                                    <input type="text" required />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <label className={classes.radio}>
                                        <input
                                            type="radio"
                                            value="cash"
                                            name="payment method"
                                            checked={paymentMethod === 'cash'}
                                            onChange={handlePayMethod}
                                        />
                                        <p>Cash on Delivery</p>
                                    </label>
                                </div>
                            </div>
                        )}
                        {paymentType === 'partial' && (
                            <div>
                                <div className={classes.selectContainer}>
                                    <p>Select the number of installments</p>
                                    <select
                                        className={classes.selector}
                                        defaultValue="DEFAULT"
                                        onChange={handleInstallment}>
                                        <option disabled value="DEFAULT">
                                            Please Select Installment Option
                                        </option>
                                        <option value="2">02 Installments</option>
                                        <option value="3">03 Installments</option>
                                    </select>
                                    <MdOutlineKeyboardArrowDown className={classes.selectArrow} size={20} />
                                </div>
                                {installment && (
                                    <div>
                                        <div className={classes.paymentMethod}>Payment Method</div>
                                        <div className={classes.half}>
                                            <div className={classes.radio}>
                                                <label className={classes.bank}>
                                                    <input
                                                        type="radio"
                                                        value="bank"
                                                        name="payment method"
                                                        checked={paymentMethod === 'bank'}
                                                        onChange={handlePayMethod}
                                                    />
                                                    <p>Bank Transfer</p>
                                                </label>
                                                {paymentMethod === 'bank' && (
                                                    <div className={classes.bankDetails}>
                                                        <hr className={classes.hr} />
                                                        <h2>Payment Details</h2>
                                                        <div className={classes.bankInfo}>
                                                            <div className={classes.infoField}>
                                                                <p>Bank Name</p>
                                                                <div>The City Bank Limited</div>
                                                            </div>
                                                            <div className={classes.infoField}>
                                                                <p>A/C Name</p>
                                                                <div>D IMPACT VENTURES BANGLADESH LTD.</div>
                                                            </div>
                                                            <div className={classes.infoField}>
                                                                <p>A/C NO</p>
                                                                <div>1253213484001</div>
                                                            </div>
                                                            <div className={classes.infoField}>
                                                                <p>Branch</p>
                                                                <div>Banani</div>
                                                            </div>
                                                            <div className={classes.infoField}>
                                                                <p>Branch Code</p>
                                                                <div>043</div>
                                                            </div>
                                                            <div className={classes.infoField}>
                                                                <p>Routing NO</p>
                                                                <div>225260438</div>
                                                            </div>
                                                        </div>
                                                        <p>After sending money please fillup the form below</p>
                                                        <div className={classes.bankDetails}>
                                                            <div className={classes.payment}>
                                                                <p>Bank Name</p>
                                                                <input type="text" required />
                                                            </div>
                                                            <div className={classes.payment}>
                                                                <p>A/C NO</p>
                                                                <input type="text" required />
                                                            </div>
                                                            <div className={classes.payment}>
                                                                <p>Amount</p>
                                                                <input type="text" required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className={classes.radio}>
                                                <label className={classes.bank}>
                                                    <input
                                                        type="radio"
                                                        value="online"
                                                        name="payment method"
                                                        checked={paymentMethod === 'online'}
                                                        onChange={handlePayMethod}
                                                    />
                                                    <p>Online Money Transfer</p>
                                                </label>
                                                {paymentMethod === 'online' && (
                                                    <div className={classes.bankDetails}>
                                                        <hr className={classes.hr} />
                                                        <h2>Payment Details</h2>
                                                        <div className={classes.bankInfo}>
                                                            <div className={classes.infoField}>
                                                                <p>Bkash Number</p>
                                                                <div>01727611963</div>
                                                            </div>
                                                        </div>
                                                        <div className={classes.payment}>
                                                            <p>Sender Phone Number</p>
                                                            <input type="text" required />
                                                        </div>
                                                        <div className={classes.payment}>
                                                            <p>Transaction ID</p>
                                                            <input type="text" required />
                                                        </div>
                                                        <div className={classes.payment}>
                                                            <p>Amount</p>
                                                            <input type="text" required />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <label className={classes.radio}>
                                                <input
                                                    type="radio"
                                                    value="cash"
                                                    name="payment method"
                                                    checked={paymentMethod === 'cash'}
                                                    onChange={handlePayMethod}
                                                />
                                                <p>Cash on Delivery</p>
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className={classes.buttonContainer}>
                    <button type="submit">Submit</button>
                </div>
            </form>
        </div>
    )
}

export default Payment
