//get category production
// import axios from 'axios'
import { apiV1 } from '../../src/utils/api'

export async function getSubAndSubsubcategoryByCategoryId(id) {
    try {
        const response = await fetch(`${apiV1}/categories/single/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data
        }
    } catch {}
}

export async function getSubcategoryByCategoryId(id) {
    try {
        const response = await fetch(`${apiV1}/categories/sub_category/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data
        }
    } catch {}
}

export async function getSubsubcategoryBySubcategoryId(id) {
    try {
        const response = await fetch(`${apiV1}/categories/sub_sub_category/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data
        }
    } catch {}
}

export async function getProductsByCategory(id) {
    try {
        const response = await fetch(`${apiV1}/products/category/${id}?skip=0&limit=100`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data[1]
        }
    } catch {}
}

export async function getProductsBySubcategory(id) {
    try {
        const response = await fetch(`${apiV1}/products/sub_category/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data[1]
        }
    } catch {}
}

export async function getProductsBySubsubcategory(id) {
    try {
        const response = await fetch(`${apiV1}/products/sub_sub_category/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data[1]
        }
    } catch {}
}

export async function getCompany(name, skip = 0, limit = 10, startDate, endDate) {
    let api = ''
    if (startDate?.length > 0 && endDate?.length > 0) {
        api = `${apiV1}/companies/?name=${name}&start_date=${startDate}&end_date=${endDate}&skip=${skip}&limit=${limit}`
    } else {
        api = `${apiV1}/companies/?name=${name}&skip=${skip}&limit=${limit}`
    }
    try {
        const response = await fetch(`${api}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return [data[0], data[1]]
        }
    } catch {}
}

export async function getSeller(skip, limit) {
    try {
        const response = await fetch(`${apiV1}/sellers/?skip=${skip}&limit=${limit}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return [data[0], data[1]]
        }
    } catch {}
}

export async function getBuyer(skip, limit) {
    try {
        const response = await fetch(`${apiV1}/buyers/?skip=${skip}&limit=${limit}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return [data[0], data[1]]
        }
    } catch {}
}

export async function getFeaturedSeller() {
    try {
        const response = await fetch(`${apiV1}/sellers/feature?skip=0&limit=10`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data[1]
        }
    } catch {}
}

export async function getFeaturedProduct(skip, limit) {
    try {
        const response = await fetch(`${apiV1}/products/feature?skip=${skip}&limit=${limit}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return [data[0], data[1]]
        }
    } catch {}
}

export async function getCategoryAllDetails() {
    try {
        const response = await fetch(`${apiV1}/categories/all`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data
        }
    } catch {}
}

export async function getCategoryAll() {
    try {
        const response = await fetch(`${apiV1}/categories/`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()
        const categoryOptions = []

        if (response.ok) {
            await data.map((e) => categoryOptions.push({ id: e.id, name: e.name, label: e.name, value: e.name }))
            return categoryOptions
        }
    } catch {}
}

export async function getCategoryProducts(categoryId) {
    try {
        const response = await fetch(`${apiV1}/products/category/${categoryId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data[1]
        }
    } catch {}
}

export async function getProducts(title, category, skip, limit) {
    try {
        const response = await fetch(
            `${apiV1}/products/?title=${title}&category=${category}&skip=${skip}&limit=${limit}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${token}`,
                },
            }
        )

        const data = await response.json()

        if (response.ok) {
            return [data[0], data[1]]
        }
    } catch {}
}

export async function getProductsByUserId(userId) {
    try {
        const response = await fetch(`${apiV1}/products/seller/${userId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${token}`,
            },
        })

        const data = await response.json()

        if (response.ok) {
            return [data[0], data[1]]
        }
    } catch {}
}

export async function getAllRfq(skip, limit) {
    try {
        const response = await fetch(`${apiV1}/rfq/?skip=${skip}&limit=${limit}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${token}`,
            },
        })

        const data = await response.json()

        if (response.ok) {
            return [data[0], data[1]]
        }
    } catch {}
}

export async function getRfqById(rfqId) {
    try {
        const response = await fetch(`${apiV1}/rfq/${rfqId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${token}`,
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data
        }
    } catch {}
}

export async function getRfq(skip, limit) {
    try {
        const response = await fetch(`${apiV1}/rfq/?skip=${skip}&limit=${limit}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${token}`,
            },
        })

        const data = await response.json()

        if (response.ok) {
            return [data[0], data[1]]
        }
    } catch {}
}
