import React from 'react'
import classes from './PopupV1.module.css'

const PopupV1 = ({ children, setForm, form, width, height }) => {
    return (
        <div className={classes.container}>
            <div className={classes.overlay} onClick={() => setForm(!form)}></div>
            <div className={classes.form} style={{ width: width, height: height }}>
                {children}
            </div>
        </div>
    )
}

export default PopupV1
