import moment from 'moment'
import React from 'react'
import { FaRegStar, FaStar } from 'react-icons/fa'
import { cdnUrl } from '../../utils/api'
import { LoadingBar } from '../Resources'
import Table from '../Resources/Table/Table'
import classes from './Products.module.css'

export default function Products(props) {
    return (
        <div className={classes.wrapper}>
            <p className={classes.txt}>
                {' '}
                Product List ({props.productCount}) --|-- Featured ({props.featureProductCount})
            </p>
            <div className={classes.filter}>
                <div className={classes.search}>
                    <input
                        type="text"
                        placeholder="Search by product id..."
                        value={props.companyName}
                        onChange={(e) => props.setProductId(e.target.value)}
                    />
                </div>
            </div>
            {
                <Table>
                    <thead>
                        <tr className={`${classes.tableRow}`}>
                            <th>Sl.</th>
                            <th>Display Image</th>
                            <th>Product Name</th>
                            <th>Category</th>
                            <th>Company Name</th>
                            <th>Featured</th>
                            <th>Creation Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.products?.map((data, index) => (
                            <tr className={classes.row} key={index}>
                                <td>{index + 1}</td>
                                <td className={classes.imgCol}>
                                    {data.ImgProduct.image_url || data.ImgProduct.image_string ? (
                                        <img
                                            alt=""
                                            className={classes.image}
                                            src={
                                                data.ImgProduct.image_url
                                                    ? data.ImgProduct.image_url
                                                    : `${cdnUrl}/product/${data.ImgProduct.image_string}`
                                            }
                                        />
                                    ) : (
                                        <div className={classes.imageEmpty}>No image</div>
                                    )}
                                </td>
                                <td>{data.Product.title}</td>
                                <td>{data.Seller.category}</td>
                                <td>{data.Seller.page_name}</td>
                                <td>
                                    {data.Product.is_feature === true ? (
                                        <FaStar className={classes.iconFeatured} />
                                    ) : (
                                        <FaRegStar className={classes.iconFeatured} />
                                    )}
                                </td>
                                <td>{moment(data.Product.created_at).format('Do MMM, YY')}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            }
            {props.products ? '' : <LoadingBar />}
        </div>
    )
}
