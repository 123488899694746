import React, { useEffect, useReducer } from 'react'
import { Routes, Route } from 'react-router-dom'
import { Auth, Category, Order, Seller, UserInfo } from './contexts/allContext'
import { orderReducer, orderState } from './contexts/reducer/OrderReducer'
import { authReducer, authState } from './contexts/reducer/authReducer'
import { categoryReducer, categoryState } from './contexts/reducer/categoryReducer'
import { sellerReducer, sellerState } from './contexts/reducer/sellerReducer'
import { userReducer, userState } from './contexts/reducer/userReducer'
import {
    SettingPage,
    ProfilePage,
    DashboardPage,
    SellerActiveListPage,
    SellerInactiveListPage,
    LogInPage,
    CreateSellerPage,
    CategoryPage,
    CategoryListPage,
    BuyerListPage,
    ProductListPage,
    RfqListPage,
} from './pages'
import CreateOrderPage from './pages/CreateOrder'
import OrderListPage from './pages/OrderList'
import Sme from './pages/Sme'
import ProtectedRoute from './routes/ProtectedRoute'

function App() {
    const [stateAuth, dispatchAuth] = useReducer(authReducer, authState)
    const [stateUser, dispatchUser] = useReducer(userReducer, userState)
    const [stateSeller, dispatchSeller] = useReducer(sellerReducer, sellerState)
    useEffect(() => {
        localStorage.removeItem('seller')
        localStorage.removeItem('category')
        localStorage.removeItem('order')
    })
    const [stateCategory, dispatchCategory] = useReducer(categoryReducer, categoryState)
    const [stateOrder, dispatchOrder] = useReducer(orderReducer, orderState)
    return (
        <Order.Provider value={{ stateOrder, dispatchOrder }}>
            <Category.Provider value={{ stateCategory, dispatchCategory }}>
                <Seller.Provider value={{ stateSeller, dispatchSeller }}>
                    <Auth.Provider value={{ stateAuth, dispatchAuth }}>
                        <UserInfo.Provider value={{ stateUser, dispatchUser }}>
                            <Routes>
                                <Route path="" element={<LogInPage />} />
                                <Route path="/*" element={<ProtectedRoute preventUser={['seller']} />}>
                                    <Route path="dashboard" element={<DashboardPage />} />
                                    <Route path="buyers" element={<BuyerListPage />} />
                                    <Route path="products" element={<ProductListPage />} />
                                    <Route path="active-sellers" element={<SellerActiveListPage />} />
                                    <Route path="inactive-sellers" element={<SellerInactiveListPage />} />
                                    <Route path="create-seller" element={<CreateSellerPage />} />
                                    <Route path="rfq-list" element={<RfqListPage />} />
                                    <Route path="setting" element={<SettingPage />} />
                                    <Route path="profile" element={<ProfilePage />} />
                                    <Route path="create-category" element={<CategoryPage />} />
                                    <Route path="category-list" element={<CategoryListPage />} />
                                    <Route path="order-list" element={<OrderListPage />} />
                                    <Route path="create-order" element={<CreateOrderPage />} />
                                    <Route path="sme" element={<Sme />} />
                                </Route>
                            </Routes>
                        </UserInfo.Provider>
                    </Auth.Provider>
                </Seller.Provider>
            </Category.Provider>
        </Order.Provider>
    )
}

export default App
