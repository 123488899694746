import React from 'react'
import classes from './Shipment.module.css'

const Shipment = () => {
    const handleSubmit = async (e) => {
        e.preventDefault()
    }
    return (
        <div className={classes.container}>
            <form onSubmit={handleSubmit}>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px' }}>
                    <div className={classes.innerContainer}>
                        <div className={classes.half}>
                            <div>
                                <p>Full Name</p>
                                <input type="text" />
                            </div>
                            <div>
                                <p>Country Name</p>
                                <input type="text" />
                            </div>
                        </div>
                        <div className={classes.half}>
                            <div>
                                <p>District</p>
                                <input type="text" />
                            </div>
                            <div>
                                <p>Sub-District</p>
                                <input type="text" />
                            </div>
                        </div>

                        <div className={classes.half}>
                            <div>
                                <p>Division</p>
                                <input type="text" />
                            </div>
                            <div>
                                <p>Phone</p>
                                <input type="number" />
                            </div>
                        </div>
                        <p>Address</p>
                        <textarea type="text" />
                    </div>
                </div>
                <div className={classes.buttonContainer}>
                    <button type="submit">Submit</button>
                </div>
            </form>
        </div>
    )
}

export default Shipment
