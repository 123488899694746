import React, { useState } from 'react'
import classes from './Business.module.css'

const Business = ({ businessDetails, token, api, setRefetch }) => {
    const [name, setName] = useState(businessDetails?.name)
    const [type, setType] = useState(businessDetails?.business_type)
    const [startDate, setStartDate] = useState(businessDetails?.start_date)
    const [kind, setKind] = useState(businessDetails?.business_kind)
    const [annual, setAnnual] = useState(businessDetails?.annual_sales)
    const [totalAnnual, setTotalAnnual] = useState(businessDetails?.annual_income)
    const [asset, setAsset] = useState(businessDetails?.assets)
    const [manpower, setManPower] = useState(businessDetails?.manpower)
    const [stock, setStock] = useState(businessDetails?.stock_cost)
    const [submitStatus, setSubmitStatus] = useState(false)
    const [msg, setMsg] = useState('')
    const handleSubmit = async (e) => {
        e.preventDefault()
        const response = await fetch(`${api}/loans/business/${businessDetails?.id}`, {
            headers: {
                Accept: 'appllication/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: 'PATCH',
            body: JSON.stringify({
                name: name,
                business_type: type,
                start_date: startDate,
                business_kind: kind,
                annual_sales: annual,
                annual_income: totalAnnual,
                assets: asset,
                manpower: manpower,
                stock_cost: stock,
            }),
        })
        if (response.ok) {
            setSubmitStatus(true)
            setRefetch(true)
            setMsg('Form Updated!')
            setTimeout(() => {
                setSubmitStatus(false)
            }, 2000)
        } else {
            setSubmitStatus(true)
            setMsg('Failed to submit form, please try again later!')
        }
    }

    return (
        <div className={classes.container}>
            <form onSubmit={handleSubmit}>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px' }}>
                    <div className={classes.innerContainer}>
                        <p>Name of Business or Company</p>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                        <div className={classes.half}>
                            <div>
                                <p>Type of Business</p>
                                <input type="text" value={type} onChange={(e) => setType(e.target.value)} />
                            </div>
                            <div>
                                <p>Business Start Date</p>
                                <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                            </div>
                        </div>
                        <p>Kind of Business</p>
                        <input type="text" value={kind} onChange={(e) => setKind(e.target.value)} />
                        <div className={classes.half}>
                            <div>
                                <p>Annual Sales</p>
                                <input type="text" value={annual} onChange={(e) => setAnnual(e.target.value)} />
                            </div>
                            <div>
                                <p>Total Annual Income</p>
                                <input
                                    type="text"
                                    value={totalAnnual}
                                    onChange={(e) => setTotalAnnual(e.target.value)}
                                />
                            </div>
                        </div>
                        <p>Fixed Assets of the Organization</p>
                        <input type="text" value={asset} onChange={(e) => setAsset(e.target.value)} />
                        <div className={classes.half}>
                            <div>
                                <p>Number of Manpower</p>
                                <input type="text" value={manpower} onChange={(e) => setManPower(e.target.value)} />
                            </div>
                            <div>
                                <p>Cost of Stock Goods</p>
                                <input type="text" value={stock} onChange={(e) => setStock(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    {submitStatus && (
                        <p
                            className={classes.submitMessage}
                            style={{ color: `${msg?.substring(0, 2) === 'Fo' ? '#5cb484' : '#a10f0f'}` }}>
                            {msg}
                        </p>
                    )}
                    <div className={classes.buttonContainer}>
                        <button type="submit">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Business
