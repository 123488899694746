import React, { useState } from 'react'
import { FiEdit } from 'react-icons/fi'
import { RiDeleteBinLine } from 'react-icons/ri'
import { apiV1 } from '../../../utils/api'
import { PopUp, SelectField } from '../../Resources'
import Table from '../../Resources/Table/Table'
import classes from './SubSubCategoryList.module.css'

export default function SubSubCategoryList(props) {
    const [newName, setNewName] = useState('')
    const [id, setId] = useState('')
    const [sl, setSl] = useState('')
    const [option, setOption] = useState('0')

    const handleEdit = () => {
        const url = `${apiV1}/categories/sub_sub_category/${id}`
        const status = 'category updated'
        const method = 'PUT'
        props.handleClick(url, method, status)
    }
    const handleDelete = () => {
        const url = `${apiV1}/categories/sub_sub_category/${id}`
        const url2 = ''
        const status = 'category deleted'
        const status2 = ''
        const method = 'DELETE'
        props.handleClick(url, newName, method, status, url2, status2)
    }
    return (
        <div className={classes.wrapper}>
            <p className={classes.txt}> Sub Sub Category List</p>
            <div className={classes.select}>
                <div>
                    <SelectField
                        search_by="-- Select category --"
                        searchable={true}
                        clearable={true}
                        options={props.categoryOption}
                        value={props.categorySelect}
                        selectOnChange={(e) => {
                            if (e) {
                                props.setCategorySelect(e)
                                props.setSubCategorySelect('')
                            } else {
                                props.setCategorySelect('')
                                props.setSubCategorySelect('')
                            }
                        }}
                    />
                </div>
                <div>
                    <SelectField
                        search_by="-- Select sub category --"
                        searchable={true}
                        clearable={true}
                        options={props.subCategoryOption}
                        value={props.subCategorySelect}
                        selectOnChange={(e) => {
                            if (e) {
                                props.setSubCategorySelect(e)
                            } else {
                                props.setSubCategorySelect('')
                            }
                        }}
                    />
                </div>
            </div>
            <Table>
                <thead>
                    <tr className={`${classes.tableRow}`}>
                        <th>Id</th>
                        <th>Category List</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                </thead>

                <tbody>
                    {props.subSubCategory &&
                        props.subSubCategory.map((data, index) => (
                            <tr className={classes.row} key={index}>
                                <td>{data.id}</td>
                                <td>
                                    {data.name}

                                    {props.clickEdit && sl === index && (
                                        <>
                                            {' -> '}
                                            <input
                                                className={classes.categoryInput}
                                                type="text"
                                                placeholder="Enter new name"
                                                value={newName}
                                                onChange={(e) => setNewName(e.target.value)}
                                            />
                                        </>
                                    )}
                                </td>
                                <td>
                                    {!props.clickEdit && (
                                        <FiEdit
                                            className={classes.icon}
                                            onClick={() => {
                                                props.setClickEdit(true)
                                                setId(data.id)
                                                setSl(index)
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    )}

                                    {props.clickEdit && sl === index && (
                                        <div className={classes.EditButton}>
                                            {newName && (
                                                <div
                                                    className={classes.save}
                                                    onClick={() => {
                                                        handleEdit()
                                                    }}>
                                                    Save
                                                </div>
                                            )}
                                            <div
                                                className={classes.cancel}
                                                onClick={() => {
                                                    props.setClickEdit(false)
                                                    setNewName('')
                                                }}>
                                                Cancel
                                            </div>
                                        </div>
                                    )}
                                </td>
                                <td>
                                    {
                                        <RiDeleteBinLine
                                            className={classes.iconDelete}
                                            onClick={() => {
                                                // props.setClickEdit(true)
                                                props.setActivePopUp(true)
                                                setId(data.id)
                                                setSl(index)
                                                setOption('3')
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    }
                                    {props.activePopUp && sl === index && option === '3' && (
                                        <PopUp
                                            setActivePopUp={props.setActivePopUp}
                                            btnClickPopUp={'2'}
                                            message={'Are you confirm?'}
                                            handleDelete={handleDelete}
                                            categoryId={data.id}
                                        />
                                    )}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </div>
    )
}
