import { faThumbtack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import classes from './Form.module.css'

export default function Form({
    setCategoryName,
    setSubCategoryName,
    setSubSubCategoryName,
    setCategoryId,
    setCategoryIds,
    setSubCategoryId,
    categoryAll,
    subCategoryAll,
    handleCategory,
    handleSubCategory,
    handleSubSubCategory,
}) {
    return (
        <div>
            <div className={classes.formWrapper}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.logo}>Create Category</div>
                <div className={classes.form}>
                    <div className={classes.innerWrap}>
                        <div className={classes.container}>
                            <form>
                                <ul>
                                    <li>
                                        <input type="checkbox" id="listItem1" />
                                        <label htmlFor="listItem1" className={classes.labelMain}>
                                            Create Category
                                        </label>
                                        <ul>
                                            <div className={classes.innerContainer}>
                                                <div className={classes.collab}>
                                                    <label>
                                                        Category Name
                                                        <input
                                                            type="text"
                                                            name=""
                                                            required
                                                            onChange={(e) => setCategoryName(e.target.value)}
                                                        />
                                                    </label>
                                                </div>

                                                <button className={classes.button} onClick={(e) => handleCategory(e)}>
                                                    SUBMIT
                                                </button>
                                            </div>
                                        </ul>
                                    </li>
                                </ul>

                                {/* Second Form  */}

                                <ul>
                                    <li>
                                        <input type="checkbox" id="listItem2" />
                                        <label htmlFor="listItem2" className={classes.labelMain}>
                                            Create SubCategory
                                        </label>
                                        <ul>
                                            <div className={classes.innerContainer}>
                                                <div className={classes.collab}>
                                                    <label>
                                                        Select Category
                                                        <select
                                                            className={classes.select}
                                                            name="category"
                                                            onChange={(e) => setCategoryId(e.target.value)}>
                                                            {categoryAll?.map((category, index) => (
                                                                <option value={category.id} key={index}>
                                                                    {category.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </label>
                                                    <label>
                                                        SubCategory Name
                                                        <input
                                                            type="text"
                                                            name=""
                                                            required
                                                            onChange={(e) => setSubCategoryName(e.target.value)}
                                                        />
                                                    </label>
                                                </div>

                                                <button
                                                    type="submit"
                                                    className={classes.button}
                                                    onClick={(e) => handleSubCategory(e)}>
                                                    SUBMIT
                                                </button>
                                            </div>
                                        </ul>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <input type="checkbox" id="listItem3" />
                                        <label htmlFor="listItem3" className={classes.labelMain}>
                                            Create SubSubCategory
                                        </label>
                                        <ul>
                                            <div className={classes.innerContainer}>
                                                <div className={classes.collab}>
                                                    <label>
                                                        Select Category
                                                        <select
                                                            className={classes.select}
                                                            name="category"
                                                            onChange={(e) => setCategoryIds(e.target.value)}>
                                                            {categoryAll?.map((category, index) => (
                                                                <option value={category.id} key={index}>
                                                                    {category.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </label>

                                                    <label>
                                                        Select SubCategory
                                                        <select
                                                            className={classes.select}
                                                            name="category"
                                                            onChange={(e) => setSubCategoryId(e.target.value)}>
                                                            {subCategoryAll?.map((subCategory, index) => (
                                                                <option value={subCategory.id} key={index}>
                                                                    {subCategory.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </label>
                                                    <label>
                                                        SubSubCategory Name
                                                        <input
                                                            type="text"
                                                            name=""
                                                            required
                                                            onChange={(e) => setSubSubCategoryName(e.target.value)}
                                                        />
                                                    </label>
                                                </div>

                                                <button
                                                    type="submit"
                                                    className={classes.button}
                                                    onClick={(e) => handleSubSubCategory(e)}>
                                                    SUBMIT
                                                </button>
                                            </div>
                                        </ul>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
