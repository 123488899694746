import React, { useContext, useEffect, useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { BarLoader } from 'react-spinners'
import { Auth, UserInfo } from '../../../../contexts/allContext'
import { apiV1 } from '../../../../utils/api'
import classes from './UpdateSellerInfo.module.css'

export default function UpdateSellerInfo(props) {
    // const img_name = props.currentImage ? `${apiV1}/images/logo/${props.currentImage}` : ''
    const [loading, setLoading] = useState(false)
    const [subscriptionPlan, setSubscriptionPlan] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [clickReset, setClickReset] = useState(false)
    useEffect(() => {
        if (props.basic) {
            setSubscriptionPlan('basic')
        } else if (props.standard) {
            setSubscriptionPlan('standard')
        } else if (props.premium) {
            setSubscriptionPlan('premium')
        }
    }, [props.basic, props.standard, props.premium])

    const { stateAuth, dispatchAuth } = useContext(Auth)
    const { dispatchUser } = useContext(UserInfo)

    const handleClick = async (url, status) => {
        try {
            if (stateAuth.token) {
                const details = {
                    password: newPassword,
                }
                setLoading(true)
                const response = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${stateAuth.token}`,
                    },
                    body: JSON.stringify(details),
                })
                const log = await response.json()
                if (response.ok) {
                    props.setCheck(true)
                    setLoading(false)
                    setNewPassword('')
                    clickReset && setClickReset(false)
                    console.log(status)
                } else {
                    console.log(log.context)
                }
            } else {
                dispatchAuth({ type: 'remove' })
                dispatchUser({ type: 'remove' })
            }
        } catch {}
    }

    const handleActive = async (value) => {
        const url = `${apiV1}/admin/user/switch/${value}`
        const status = 'active call switch'
        handleClick(url, status)
        !props.check && props.setActivePopUp(false)
    }

    const handleFeatured = async (value) => {
        const url = `${apiV1}/admin/seller/feature/${value}`
        const status = 'feature call switch'
        handleClick(url, status)
    }

    const handleVerified = async (value) => {
        const url = `${apiV1}/admin/seller/verify/${value}`
        const status = 'verify call switch'
        handleClick(url, status)
    }

    const handleBasic = async (value) => {
        const url = `${apiV1}/admin/seller/basic/${value}`
        const status = 'subscription plan call switch'
        handleClick(url, status)
    }

    const handleStandard = async (value) => {
        const url = `${apiV1}/admin/seller/standard/${value}`
        const status = 'subscription plan call switch'
        handleClick(url, status)
    }

    const handlePremium = async (value) => {
        const url = `${apiV1}/admin/seller/premium/${value}`
        const status = 'subscription plan call switch'
        handleClick(url, status)
    }

    const handleReset = async (e, value) => {
        e.preventDefault()
        const url = `${apiV1}/admin/user/password/${value}`
        const status = 'password reset switch'
        handleClick(url, status)
    }

    return (
        <div className={classes.wrapper}>
            {loading && (
                <div className={classes.loading}>
                    <BarLoader width={'100%'} color="#ff7f50" />
                </div>
            )}

            <div onClick={() => props.setActivePopUp(false)} className={classes.close}>
                <AiFillCloseCircle className={classes.closeBtn} />
            </div>
            <div className={classes.title}>
                <div>Update Seller information</div>
                <div>
                    U{props.userId}-S{props.sellerId} | {props.sellerName}
                </div>
            </div>
            <div className={classes.action}>
                <div>
                    <div>Active</div>
                    <div className={classes.toggle}>
                        <div
                            onClick={() => (!props.active ? handleActive(props.userId) : '')}
                            className={props.active ? classes.active : classes.toggleBtn}>
                            Yes
                        </div>
                        <div
                            onClick={() => (props.active ? handleActive(props.userId) : '')}
                            className={!props.active ? classes.inactive : classes.toggleBtn}>
                            No
                        </div>
                    </div>
                </div>
                <div>
                    <div>Featured</div>
                    <div className={classes.toggle}>
                        <div
                            onClick={() => (!props.featured ? handleFeatured(props.sellerId) : '')}
                            className={props.featured ? classes.active : classes.toggleBtn}>
                            Yes
                        </div>
                        <div
                            onClick={() => (props.featured ? handleFeatured(props.sellerId) : '')}
                            className={!props.featured ? classes.inactive : classes.toggleBtn}>
                            No
                        </div>
                    </div>
                </div>
                <div>
                    <div>Verified</div>
                    <div className={classes.toggle}>
                        <div
                            onClick={() => (!props.verified ? handleVerified(props.sellerId) : '')}
                            className={props.verified ? classes.active : classes.toggleBtn}>
                            Yes
                        </div>
                        <div
                            onClick={() => (props.verified ? handleVerified(props.sellerId) : '')}
                            className={!props.verified ? classes.inactive : classes.toggleBtn}>
                            No
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.subscriptionPlan}>
                <div>Subscription plan chosen</div>
                <div className={classes.toggle}>
                    <div
                        onClick={() => handleBasic(props.sellerId)}
                        className={subscriptionPlan === 'basic' ? classes.active : classes.toggleBtn}>
                        Basic
                    </div>
                    <div
                        onClick={() => handleStandard(props.sellerId)}
                        className={subscriptionPlan === 'standard' ? classes.active : classes.toggleBtn}>
                        Standard
                    </div>
                    <div
                        onClick={() => handlePremium(props.sellerId)}
                        className={subscriptionPlan === 'premium' ? classes.active : classes.toggleBtn}>
                        Premium
                    </div>
                </div>
            </div>
            <div className={classes.passwordReset}>
                <div onClick={() => (!clickReset ? setClickReset(true) : setClickReset(false))} id={classes.resetTitle}>
                    Reset Password
                </div>
                <form
                    onSubmit={(e) => handleReset(e, props.userId)}
                    className={!clickReset ? classes.resetEmpty : classes.reset}>
                    <input
                        type="text"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                    <button type="submit">Save</button>
                </form>
            </div>
        </div>
    )
}
