import React, { useState } from 'react'
import { LuMinus } from 'react-icons/lu'
import { apiV1 } from '../../../../../utils/api'
import classes from './Product.module.css'

const Product = ({ id, setInputList, inputList }) => {
    const [productName, setProductName] = useState()
    const [productId, setProductId] = useState('')
    const [sellerName, setSellerName] = useState()
    const [moq, setMoq] = useState()
    const [error, setError] = useState('')
    const [stockQuantity, setStockQuantity] = useState()
    const handleMinus = () => {
        setInputList(inputList.filter((data) => data.id !== id))
    }
    const handleSelect = (e) => {
        e.preventDefault()
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiV1}/products/${parseInt(productId) - 10000}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })

                const data = await response.json()

                if (response.ok) {
                    setProductName(data?.Product?.title)
                    setSellerName(data?.Seller?.page_name)
                    setMoq(data?.Price?.moq)
                    setStockQuantity(data?.Product?.quantity)
                    setError('')
                }
                const objectToFind = inputList.findIndex((data) => data.id === id)

                if (objectToFind !== -1) {
                    const updatedData = [...inputList]

                    updatedData[objectToFind] = {
                        ...updatedData[objectToFind],
                        title: data?.Product?.title,
                        page_name: data?.Seller?.page_name,
                        moq: data?.Price?.moq,
                        quantity: data?.Product?.quantity,
                    }
                    setInputList(updatedData)
                }
            } catch (e) {
                setError('Product not found!')
                setProductName('')
                setSellerName('')
                setMoq('')
                setStockQuantity('')
                const objectToFind = inputList.findIndex((data) => data.id === id)

                if (objectToFind !== -1) {
                    const updatedData = [...inputList]

                    updatedData[objectToFind] = {
                        id: id,
                    }
                    setInputList(updatedData)
                }
            }
        }
        fetchData()
    }

    return (
        <div className={classes.container}>
            <div className={classes.productBorder}>
                <div className={classes.productSearch}>
                    <p className={classes.id}>{id}.</p>
                    <div className={classes.search}>
                        <p>Product ID</p>
                        <div className={classes.productSelect}>
                            <input type="text" onChange={(e) => setProductId(e.target.value)} />
                            <div>
                                <button onClick={handleSelect}>Select</button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.minusContainer}>
                        <button onClick={handleMinus}>
                            <LuMinus size={15} />
                        </button>
                    </div>
                </div>
                {error && <p className={classes.error}>{error}</p>}
                {productName && (
                    <div>
                        <div className={classes.ProductName}>
                            <div>
                                <p>Product Name</p>
                                <div>{productName || ''}</div>
                            </div>
                            <div>
                                <p>Seller Name</p>
                                <div>{sellerName || ''}</div>
                            </div>
                            <div>
                                <p>MOQ</p>
                                <div>{moq || ''}</div>
                            </div>
                            <div>
                                <p>Stock Quantity</p>
                                <div>{stockQuantity || ''}</div>
                            </div>
                        </div>
                        <div className={classes.productDetails}>
                            <div>
                                <p>Quantity</p>
                                <input type="number" />
                            </div>
                            <div>
                                <p>Attribute</p>
                                <input type="text" />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Product
