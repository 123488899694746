import React from 'react'
import { IoClose } from 'react-icons/io5'
import PopupV1 from '../../Resources/PopupV1/PopupV1'
import Buyer from './Buyer/Buyer'
import Delivery from './Delivery/Delivery'
import classes from './OrderPopup.module.css'
import Payment from './Payment/Payment'
import Selection from './Selection/Selection'
import Shipment from './Shipment/Shipment'

const OrderPopup = ({ form, setForm }) => {
    return (
        <div className={classes.container}>
            <PopupV1 width="70%" height="70vh" setForm={setForm} form={form}>
                <div className={classes.close}>
                    <div className={classes.header}>
                        <p>Order Details</p>
                    </div>
                    <button onClick={() => setForm(!form)}>
                        <IoClose size={20} />
                    </button>
                </div>
                <div className={classes.content}>
                    <hr />
                    <div className={classes.main}>
                        <div>
                            <input type="checkbox" id="box0" />
                            <label htmlFor="box0" className={classes.formItem}>
                                Buyer Details
                            </label>
                            <Buyer />
                        </div>
                        <div>
                            <input type="checkbox" id="box1" />
                            <label htmlFor="box1" className={classes.formItem}>
                                Product Details
                            </label>
                            <Selection />
                        </div>
                        <div>
                            <input type="checkbox" id="box2" />
                            <label htmlFor="box2" className={classes.formItem}>
                                Shipment Details
                            </label>
                            <Shipment />
                        </div>
                        <div>
                            <input type="checkbox" id="box4" />
                            <label htmlFor="box4" className={classes.formItem}>
                                Delivery Details
                            </label>
                            <Delivery />
                        </div>
                        <div>
                            <input type="checkbox" id="box3" />
                            <label htmlFor="box3" className={classes.formItem}>
                                Payment Details
                            </label>
                            <Payment />
                        </div>
                    </div>
                </div>
            </PopupV1>
        </div>
    )
}

export default OrderPopup
