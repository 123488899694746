export const StartDate = (date) => {
    let updatedDate = ''
    if (date.length > 0) {
        let dateArray = date.split('T')
        let time = dateArray[1]
        time = time.split(':')
        time[0] = '00'
        time[1] = '00'
        time[2] = '01'
        updatedDate = dateArray[0] + 'T' + time[0] + ':' + time[1] + ':' + time[2]
    }
    return updatedDate
}
export const EndDate = (date) => {
    let updatedDate = ''
    if (date.length > 0) {
        let dateArray = date.split('T')
        let time = dateArray[1]
        time = time.split(':')
        time[0] = '23'
        time[1] = '59'
        time[2] = '59'
        updatedDate = dateArray[0] + 'T' + time[0] + ':' + time[1] + ':' + time[2]
    }
    return updatedDate
}
