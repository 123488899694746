// import { useState } from 'react'
import Pagination from '../Pagination/Pagination'
import Table from '../Resources/Table/Table'
import classes from './Buyers.module.css'

export default function Buyers({ buyers, pageSkip, setPageSkip, pageAll, setCheck, check }) {
    // const [activePopUp, setActivePopUp] = useState(false)

    return (
        <div className={classes.wrapper}>
            <p className={classes.txt}> Buyer List ({pageAll})</p>
            <Table>
                <thead>
                    <tr className={`${classes.tableRow}`}>
                        <th>Sl.</th>
                        <th>Buyer ID</th>
                        <th>Buyer Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Company Name</th>
                        <th>Country</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {buyers &&
                        buyers.map((data, index) => (
                            <tr className={classes.row} key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    BIZ-U{data.Buyer.user_id}-B{data.Buyer.id + 100}
                                </td>
                                <td>{data.User.name}</td>
                                <td>{data.User.email}</td>
                                <td>{data.User.phone}</td>
                                <td>{data.Buyer.company_name}</td>
                                <td>{data.Buyer.country}</td>
                                <td>--</td>
                            </tr>
                        ))}
                </tbody>
            </Table>

            {/* {show && <Edit setShow={setShow} />} */}
            {/* {show && <PopUP setShow={setShow} userId={userID} isActive={isActive} />} */}

            <Pagination pageSkip={pageSkip} setPageSkip={setPageSkip} listItem={buyers} pageAll={pageAll} />
        </div>
    )
}
