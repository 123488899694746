import React, { useRef } from 'react'
import { FaCheck } from 'react-icons/fa6'
import { IoClose } from 'react-icons/io5'
import { useReactToPrint } from 'react-to-print'
import logo from '../../../assets/Sme/Pdf/pdfLogo.png'
import { formatDate } from '../../../utils/dateFormat'
import PopupV1 from '../../Resources/PopupV1/PopupV1'
import NumberBlock from './NumberBlock/NumberBlock'
import classes from './Pdf.module.css'
import Section from './Section/Section'

const Pdf = ({ setForm, form, data }) => {
    const applicationDateString = formatDate(data?.SmeLoan.created_at).split('-')
    const personalDobString = formatDate(data?.SmePersonal?.dob).split('-')
    const personalPhoneString = data?.SmePersonal?.phone
    const businessStartDateString = formatDate(data?.SmeBusiness?.start_date).split('-')
    const guarantorDobString = formatDate(data?.SmeGuarantor?.dob).split('-')
    const guarantorPhoneString = data?.SmeGuarantor?.phone
    const dobFormatter = (data) => {
        const dateArray = []
        for (const str of data) {
            const intArray = []

            for (const char of str) {
                const intValue = parseInt(char, 10)

                intArray.push(intValue)
            }

            dateArray.push(intArray)
        }
        return dateArray
    }
    const phoneFormatter = (data) => {
        const dataArray = []
        for (const char of data) {
            const intValue = parseInt(char, 10)

            dataArray.push(intValue)
        }
        return dataArray
    }

    const applicationDate = dobFormatter(applicationDateString)
    const personalDob = dobFormatter(personalDobString)
    const guarantorDob = dobFormatter(guarantorDobString)
    const businessStartDate = dobFormatter(businessStartDateString)
    const componentRef = useRef(null)
    const handleToPrint = useReactToPrint({
        content: () => componentRef.current,
    })
    const personalPhone = phoneFormatter(personalPhoneString)
    const guarantorPhone = phoneFormatter(guarantorPhoneString)

    return (
        <div>
            <PopupV1 setForm={setForm} form={form} width="400px">
                <div className={classes.close}>
                    <button onClick={() => setForm(!form)}>
                        <IoClose size={20} />
                    </button>
                </div>
                <div className={classes.header}>
                    <p>PDF Downloader</p>
                </div>
                <div className={classes.contentContainer} ref={componentRef}>
                    <div className={classes.content}>
                        <div className={classes.contentHeader}>
                            <img src={logo} alt="" className={classes.img} />
                            <p>SME Finance Loan Application Form</p>
                        </div>
                        <Section name="BANK DETAILS" />
                        <div className={classes.formData}>
                            <div className={classes.formRow}>
                                <p>Date of Application:</p>
                                <div className={classes.numberField}>
                                    <div>
                                        {applicationDate[0]?.map((digit, i) => {
                                            return <NumberBlock digit={digit} key={i} />
                                        })}
                                    </div>
                                    <div className={classes.numberSet}>
                                        {applicationDate[1]?.map((digit, i) => {
                                            return <NumberBlock digit={digit} key={i} />
                                        })}
                                    </div>
                                    <div>
                                        {applicationDate[2]?.map((digit, i) => {
                                            return <NumberBlock digit={digit} key={i} />
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Bank Name:</p>
                                <div className={classes.formField}>{data?.SmeBank?.bank_name}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Type of SME Loan:</p>
                                <div className={classes.formField}>{data?.SmeBank?.loan_name}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Loan Amount (in BDT):</p>
                                <div className={classes.formField}>{data?.SmeBank?.loan_amount}</div>
                            </div>
                        </div>
                        <Section name="PERSONAL INFORMATION" />
                        <div className={classes.formData}>
                            <div className={classes.formRow}>
                                <p>Name:</p>
                                <div className={classes.formField}>{data?.SmePersonal.name}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Gender:</p>
                                <div className={classes.numberField}>
                                    <div className={classes.checkItem}>
                                        {data?.SmePersonal.gender.toLowerCase() === 'male' ? (
                                            <div className={classes.checkbox}>
                                                <FaCheck size={10} />
                                            </div>
                                        ) : (
                                            <div className={classes.checkbox}></div>
                                        )}
                                        <p>Male</p>
                                    </div>
                                    <div className={classes.checkItem}>
                                        {data?.SmePersonal.gender.toLowerCase() === 'female' ? (
                                            <div className={classes.checkbox}>
                                                <FaCheck size={10} />
                                            </div>
                                        ) : (
                                            <div className={classes.checkbox}></div>
                                        )}
                                        <p>Female</p>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.formRow}>
                                <p>DOB:</p>
                                <div className={classes.numberField}>
                                    <div>
                                        {personalDob[0]?.map((digit, i) => {
                                            return <NumberBlock digit={digit} key={i} />
                                        })}
                                    </div>
                                    <div className={classes.numberSet}>
                                        {personalDob[1]?.map((digit, i) => {
                                            return <NumberBlock digit={digit} key={i} />
                                        })}
                                    </div>
                                    <div>
                                        {personalDob[2]?.map((digit, i) => {
                                            return <NumberBlock digit={digit} key={i} />
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Phone Number:</p>
                                <div className={classes.numberField}>
                                    <div>
                                        {personalPhone?.map((digit, i) => {
                                            return <NumberBlock digit={digit} key={i} />
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Present Address:</p>
                                <div className={classes.formField}>{data?.SmePersonal?.present_address}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Permanent Address:</p>
                                <div className={classes.formField}>{data?.SmePersonal?.permanent_address}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>TIN:</p>
                                <div className={classes.formField}>{data?.SmePersonal?.tin_number}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>NID:</p>
                                <div className={classes.formField}>{data?.SmePersonal?.nid_number}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Submitted Document:</p>
                                <div className={classes.numberField}>
                                    <div className={classes.checkItem}>
                                        {data?.SmePersonal?.tin_number ? (
                                            <div className={classes.checkbox}>
                                                <FaCheck size={10} />
                                            </div>
                                        ) : (
                                            <div className={classes.checkbox}></div>
                                        )}
                                        <p>TIN</p>
                                    </div>
                                    <div className={classes.checkItem}>
                                        {data?.SmePersonal?.nid_number ? (
                                            <div className={classes.checkbox}>
                                                <FaCheck size={10} />
                                            </div>
                                        ) : (
                                            <div className={classes.checkbox}></div>
                                        )}
                                        <p>NID</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Section name="BUSINESS INFORMATION" />
                        <div className={classes.formData}>
                            <div className={classes.formRow}>
                                <p>Name of Business:</p>
                                <div className={classes.formField}>{data?.SmeBusiness.name}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Type of Business:</p>

                                <div className={classes.formField}>{data?.SmeBusiness.business_type}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Business Start Date:</p>
                                <div className={classes.numberField}>
                                    <div>
                                        {businessStartDate[0]?.map((digit, i) => {
                                            return <NumberBlock digit={digit} key={i} />
                                        })}
                                    </div>
                                    <div className={classes.numberSet}>
                                        {businessStartDate[1]?.map((digit, i) => {
                                            return <NumberBlock digit={digit} key={i} />
                                        })}
                                    </div>
                                    <div>
                                        {businessStartDate[2]?.map((digit, i) => {
                                            return <NumberBlock digit={digit} key={i} />
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Kind of Business:</p>
                                <div className={classes.formField}>{data?.SmeBusiness.business_kind}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Annual Sales (in BDT):</p>
                                <div className={classes.formField}>{data?.SmeBusiness?.annual_sales}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Annual Income (in BDT):</p>
                                <div className={classes.formField}>{data?.SmeBusiness?.annual_income}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Fixed Assets of the Organization:</p>
                                <div className={classes.formField}>{data?.SmeBusiness?.assets}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Number of Manpower:</p>
                                <div className={classes.formField}>{data?.SmeBusiness?.manpower}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Cost of Stock Goods:</p>
                                <div className={classes.formField}>{data?.SmeBusiness?.stock_cost}</div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.pagination}>Page 01 of 02</div>

                    <div style={{ pageBreakAfter: 'always' }} />
                    <div className={classes.content}>
                        <div className={classes.page2HeaderGap}></div>
                        <div className={classes.contentHeader}>
                            <img src={logo} alt="" className={classes.img} />

                            <p>SME Finance Loan Application Form</p>
                        </div>
                        <Section name="GUARANTOR INFORMATION" />
                        <div className={classes.formData}>
                            <div className={classes.formRow}>
                                <p>Name of Guarantor:</p>
                                <div className={classes.formField}>{data?.SmeGuarantor.name}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Gender:</p>
                                <div className={classes.numberField}>
                                    <div className={classes.checkItem}>
                                        {data?.SmeGuarantor.gender.toLowerCase() === 'male' ? (
                                            <div className={classes.checkbox}>
                                                <FaCheck size={10} />
                                            </div>
                                        ) : (
                                            <div className={classes.checkbox}></div>
                                        )}
                                        <p>Male</p>
                                    </div>
                                    <div className={classes.checkItem}>
                                        {data?.SmePersonal.gender.toLowerCase() === 'female' ? (
                                            <div className={classes.checkbox}>
                                                <FaCheck size={10} />
                                            </div>
                                        ) : (
                                            <div className={classes.checkbox}></div>
                                        )}
                                        <p>Female</p>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.formRow}>
                                <p>DOB:</p>
                                <div className={classes.numberField}>
                                    <div>
                                        {guarantorDob[0]?.map((digit, i) => {
                                            return <NumberBlock digit={digit} key={i} />
                                        })}
                                    </div>
                                    <div className={classes.numberSet}>
                                        {guarantorDob[1]?.map((digit, i) => {
                                            return <NumberBlock digit={digit} key={i} />
                                        })}
                                    </div>
                                    <div>
                                        {guarantorDob[2]?.map((digit, i) => {
                                            return <NumberBlock digit={digit} key={i} />
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Phone Number:</p>
                                <div className={classes.numberField}>
                                    <div>
                                        {guarantorPhone?.map((digit, i) => {
                                            return <NumberBlock digit={digit} key={i} />
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Present Address:</p>
                                <div className={classes.formField}>{data?.SmePersonal?.present_address}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Permanent Address:</p>
                                <div className={classes.formField}>{data?.SmePersonal?.permanent_address}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Occupation:</p>
                                <div className={classes.formField}>{data?.SmeGuarantor?.occupation}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Monthly Income (in BDT):</p>
                                <div className={classes.formField}>{data?.SmeGuarantor?.monthly_income}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Amount of Guarantor Assets:</p>
                                <div className={classes.formField}>{data?.SmeGuarantor?.assets}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Relationship with Applicant:</p>
                                <div className={classes.formField}>{data?.SmeGuarantor?.relationship}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>TIN:</p>
                                <div className={classes.formField}>{data?.SmeGuarantor?.tin_number}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>NID:</p>
                                <div className={classes.formField}>{data?.SmeGuarantor?.nid_number}</div>
                            </div>
                            <div className={classes.formRow}>
                                <p>Submitted Document:</p>
                                <div className={classes.numberField}>
                                    <div className={classes.checkItem}>
                                        {data?.SmeGuarantor?.tin_number ? (
                                            <div className={classes.checkbox}>
                                                <FaCheck size={10} />
                                            </div>
                                        ) : (
                                            <div className={classes.checkbox}></div>
                                        )}
                                        <p>TIN</p>
                                    </div>
                                    <div className={classes.checkItem}>
                                        {data?.SmeGuarantor?.nid_number ? (
                                            <div className={classes.checkbox}>
                                                <FaCheck size={10} />
                                            </div>
                                        ) : (
                                            <div className={classes.checkbox}></div>
                                        )}
                                        <p>NID</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Section name="OTHER DOCUMENTS" />
                        <div className={classes.formData}>
                            <div className={classes.formRow}>
                                <p>Submitted Document:</p>
                                <div className={classes.checkGroup}>
                                    <div className={classes.checkItem}>
                                        {data?.SmeDocument?.trade_license_string ? (
                                            <div className={classes.checkbox}>
                                                <FaCheck size={10} />
                                            </div>
                                        ) : (
                                            <div className={classes.checkbox}></div>
                                        )}
                                        <p>Trade License</p>
                                    </div>
                                    <div className={classes.checkItem}>
                                        {data?.SmeDocument?.business_tin_string ? (
                                            <div className={classes.checkbox}>
                                                <FaCheck size={10} />
                                            </div>
                                        ) : (
                                            <div className={classes.checkbox}></div>
                                        )}
                                        <p>Business TIN</p>
                                    </div>
                                    <div className={classes.checkItem}>
                                        {data?.SmeDocument?.incorporation_certificate_string ? (
                                            <div className={classes.checkbox}>
                                                <FaCheck size={10} />
                                            </div>
                                        ) : (
                                            <div className={classes.checkbox}></div>
                                        )}
                                        <p>Incorporation Certificate</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.pagiGap2}></div>
                    <div className={classes.pagination}>Page 02 of 02</div>
                </div>
                <div className={classes.buttonContainer}>
                    <button onClick={handleToPrint} className={classes.download}>
                        Download PDF
                    </button>
                    <button className={classes.cancel} onClick={() => setForm(!form)}>
                        Cancel
                    </button>
                </div>
            </PopupV1>
        </div>
    )
}

export default Pdf
