import React, { useState } from 'react'
import classes from './BankDetails.module.css'

const BankDetails = ({ bankDetails, api, token, setRefetch }) => {
    const [type, setType] = useState(bankDetails?.loan_name)
    const [amount, setAmount] = useState(bankDetails?.loan_amount)
    const [submitStatus, setSubmitStatus] = useState(false)
    const [msg, setMsg] = useState('')
    const handleSubmit = async (e) => {
        e.preventDefault()
        const response = await fetch(`${api}/loans/bank/${bankDetails?.id}`, {
            headers: {
                Accept: 'appllication/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: 'PATCH',
            body: JSON.stringify({
                bank_name: bankDetails?.bank_name,
                loan_name: type,
                loan_amount: amount,
            }),
        })
        if (response.ok) {
            setSubmitStatus(true)
            setRefetch(true)
            setMsg('Form Updated!')
            setTimeout(() => {
                setSubmitStatus(false)
            }, 2000)
        } else {
            setSubmitStatus(true)
            setMsg('Failed to submit form, please try again later!')
        }
    }
    return (
        <div className={classes.container}>
            <form onSubmit={handleSubmit}>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px' }}>
                    <div className={classes.innerContainer}>
                        <p>Bank Name</p>
                        <div>{bankDetails?.bank_name}</div>
                        <p>Type of SME Loan</p>
                        <input type="text" value={type} onChange={(e) => setType(e.target.value)} />
                        <p>Loan Amount</p>
                        <input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} />
                    </div>
                    {submitStatus && (
                        <p
                            className={classes.submitMessage}
                            style={{ color: `${msg?.substring(0, 2) === 'Fo' ? '#5cb484' : '#a10f0f'}` }}>
                            {msg}
                        </p>
                    )}
                    <div className={classes.buttonContainer}>
                        <button type="submit">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default BankDetails
