import React from 'react'
import { Layout, Setting } from '../components'

export default function SettingPage() {
    return (
        <Layout>
            <Setting />
        </Layout>
    )
}
