export const UserGetStorage = (key) => {
    let data = localStorage.getItem(key)
    if (data) {
        return data
    } else {
        return false
    }
}
export const SellerGetStorage = (key) => {
    let data = localStorage.getItem(key)
    if (data) {
        return data
    } else {
        return false
    }
}
export const OrderGetStorage = (key) => {
    let data = localStorage.getItem(key)
    if (data) {
        return data
    } else {
        return false
    }
}
export const CategoryGetStorage = (key) => {
    let data = localStorage.getItem(key)
    if (data) {
        return data
    } else {
        return false
    }
}

export const UserSetStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
}

export const UserDefStorage = (key, def) => {
    if (UserGetStorage(key) === false) {
        UserSetStorage(key, def)
    }
    return JSON.parse(UserGetStorage(key))
}
export const SellerStateStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
}
export const CategoryStateStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
}
export const OrderStateStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
}
export const SellerDefStorage = (key, def) => {
    if (SellerGetStorage(key) === false) {
        SellerStateStorage(key, def)
    }

    return JSON.parse(SellerGetStorage(key))
}
export const CategoryDefStorage = (key, def) => {
    if (CategoryGetStorage(key) === false) {
        CategoryStateStorage(key, def)
    }

    return JSON.parse(SellerGetStorage(key))
}
export const OrderDefStorage = (key, def) => {
    if (CategoryGetStorage(key) === false) {
        CategoryStateStorage(key, def)
    }

    return JSON.parse(SellerGetStorage(key))
}
