import React, { useState, useEffect } from 'react'
import { useContext } from 'react'
import { Auth, UserInfo } from '../../contexts/allContext'
import Password from './Password/Password'
import Personal from './Personal/Personal'
import Role from './Role/Role'
import classes from './Setting.module.css'
import UserPassword from './UserPassword/UserPassword'

export default function Setting() {
    const [selected, setSelected] = useState('personal')

    const { stateUser } = useContext(UserInfo)
    const { stateAuth } = useContext(Auth)
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPass, setConfirmPass] = useState('')
    const apiV1 = process.env.REACT_APP_API_URL_V1
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiV1}/users/${stateUser?.info.id}`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${stateAuth?.token}`,
                    },
                })
                const data = await response.json()
                if (response.ok) {
                    setFullName(data?.name)
                    setEmail(data?.email)
                    setPhone(data?.phone)
                } else {
                }
            } catch (e) {}
        }
        fetchData()
    }, [apiV1, stateAuth, stateUser])

    return (
        <div>
            <div className={classes.wrapper}>
                <div className={classes.tabSection}>
                    <span
                        className={`${selected === 'personal' ? classes.active : classes.tab}`}
                        onClick={() => setSelected('personal')}>
                        Personal Information
                    </span>
                    <span
                        className={`${selected === 'password' ? classes.active : classes.tab}`}
                        onClick={() => setSelected('password')}>
                        Change Password
                    </span>
                    <span
                        className={`${selected === 'role' ? classes.active : classes.tab}`}
                        onClick={() => setSelected('role')}>
                        Change Role
                    </span>
                </div>
                <div className={classes.content}>
                    {selected === 'personal' ? (
                        <Personal
                            fullName={fullName}
                            setFullName={setFullName}
                            email={email}
                            setEmail={setEmail}
                            phone={phone}
                            setPhone={setPhone}
                            api={apiV1}
                            id={stateUser?.info.id}
                            token={stateAuth?.token}
                        />
                    ) : selected === 'password' ? (
                        <div>
                            <Password
                                password={password}
                                setPassword={setPassword}
                                confirmPass={confirmPass}
                                setConfirmPass={setConfirmPass}
                                api={apiV1}
                                id={stateUser?.info.id}
                                token={stateAuth?.token}
                            />
                            <div style={{ marginTop: '2em' }}></div>
                        </div>
                    ) : (
                        <Role />
                    )}
                </div>
            </div>
            {selected === 'password' && <UserPassword />}
        </div>
    )
}
