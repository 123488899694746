import React from 'react'
import { useState, useEffect } from 'react'
import { Layout, Products } from '../components'
import { getProducts } from '../utils/fetchData'
import { getFeaturedProduct } from '../utils/functions'
import useDebounce from '../utils/useDebounce'

export default function ProductListPage() {
    const [products, setProducts] = useState([])
    const [productCount, setProductCount] = useState(0)
    const [productId, setProductId] = useState('')
    const debouncedProductId = useDebounce(productId, 800)
    useEffect(() => {
        // Function to fetch featured data
        const fetchData = () => {
            getProducts('', '', 0, productCount)
                .then((items) => {
                    setProductCount(items[0].results)
                    setProducts(items[1])
                })
                .catch((error) => {
                    console.error('Error fetching data:', error)
                })
        }

        fetchData()
    }, [productCount])

    const [featureProductCount, setFeatureProductCount] = useState([])
    useEffect(() => {
        // Function to fetch featured data
        const fetchData = () => {
            getFeaturedProduct(0, 0)
                .then((items) => {
                    setFeatureProductCount(items[0].results)
                })
                .catch((error) => {
                    console.error('Error fetching data:', error)
                })
        }

        fetchData()
    }, [])

    return (
        <Layout>
            <Products
                products={products}
                productCount={productCount}
                featureProductCount={featureProductCount}
                setProductId={setProductId}
            />
        </Layout>
    )
}
