import React from 'react'
import { useState, useEffect } from 'react'
import { Layout, InActiveSellers } from '../components'
import { apiV1 } from '../utils/api'

export default function SellerInactiveListPage() {
    const [sellers, setSellers] = useState([])
    const [pageSkip, setPageSkip] = useState(0)
    const [pageAll, setPageAll] = useState(0)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiV1}/companies/inactive?skip=${pageSkip}&limit=10`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })

                const data = await response.json()

                if (response.ok) {
                    setSellers(data[1])
                    setPageAll(data[0].results)
                }
            } catch {
                setSellers([])
            }
        }
        fetchData()
    }, [pageSkip])
    return (
        <Layout>
            <InActiveSellers sellers={sellers} pageAll={pageAll} pageSkip={pageSkip} setPageSkip={setPageSkip} />
        </Layout>
    )
}
