import { OrderDefStorage, OrderGetStorage, OrderStateStorage } from '../storage/storage'

export const orderState = OrderDefStorage('order', { status: false })
export const orderReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            OrderStateStorage('order', { status: true })
            return JSON.parse(OrderGetStorage('order'))
        case 'close':
            OrderStateStorage('order', { status: false })
            return JSON.parse(OrderGetStorage('order'))
        default:
            return state
    }
}
