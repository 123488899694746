import React from 'react'
import classes from './Section.module.css'

const Section = ({ name }) => {
    return (
        <div className={classes.container}>
            <div className={classes.content}>{name}</div>
        </div>
    )
}

export default Section
