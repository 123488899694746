import React, { useContext } from 'react'
import { FaUser } from 'react-icons/fa'
import { TbLogout } from 'react-icons/tb'
import { Auth, UserInfo } from '../../../contexts/allContext'
import classes from './NavBar.module.css'

export default function NavBar() {
    const { dispatchAuth } = useContext(Auth)
    const { stateUser, dispatchUser } = useContext(UserInfo)
    const admin = stateUser.info

    const handleLogout = () => {
        dispatchAuth({ type: 'remove' })
        dispatchUser({ type: 'remove' })
    }

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <div>
                    <FaUser className={classes.icon} />
                    {admin.name}
                    <TbLogout onClick={() => handleLogout()} className={classes.logoutIcon} />
                </div>
            </div>
        </div>
    )
}
