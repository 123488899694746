import { useEffect, useState } from 'react'
import classes from './Pagination.module.css'

export default function Pagination({ pageSkip, setPageSkip, listItem, pageAll, limit, item }) {
    const [pageCount, setPageCount] = useState(1)
    const [buttonPrev, setButtonPrev] = useState(false)
    const [buttonNext, setButtonNext] = useState(true)
    const [pageNumber, setPageNumber] = useState(1)

    const pageItem = item === '30' ? 30 : 10

    const pageIncrease = () => {
        //   const pageIn = pageAll % 10;

        if (listItem.length >= 9) {
            setPageSkip((prev) => prev + 10)
            setPageCount((prev) => prev + 1)
            setPageNumber((prev) => prev + 1)
        } else {
            setButtonNext(false)
        }
    }

    const pageDecrease = () => {
        if (pageSkip >= 0 && pageCount !== 1) {
            setPageSkip((prev) => prev - 10)
            setPageCount((prev) => prev - 1)
            setPageNumber((prev) => prev - 1)
        } else {
            setButtonPrev(false)
        }
    }

    useEffect(() => {
        if (pageNumber > 1) {
            setPageSkip((pageNumber - 1) * 10)
            setPageCount(pageNumber)
        } else {
            setPageSkip(0)
            setPageCount(1)
        }
    }, [pageNumber])

    useEffect(() => {
        if (pageCount <= 1) {
            setButtonPrev(false)
        } else {
            setButtonPrev(true)
        }
    }, [pageCount])

    useEffect(() => {
        if (Math.ceil(pageAll / pageItem) <= pageCount) {
            setButtonNext(false)
        } else {
            setButtonNext(true)
        }
    }, [pageCount, pageAll, pageItem])

    return (
        <>
            <div className={classes.pageShow}>
                Page: {pageCount} of {Math.ceil(pageAll / pageItem) !== 0 ? Math.ceil(pageAll / pageItem) || '1' : 1}
            </div>
            <div className={classes.pageWrapper}>
                <ul className={classes.pageContainer}>
                    <div className={buttonPrev ? classes.btn : classes.btnOff} onClick={pageDecrease}>
                        &laquo; Previous
                    </div>
                    <input
                        type="text"
                        placeholder={pageCount}
                        value={pageNumber > 0 ? pageNumber : ''}
                        onChange={(e) => setPageNumber(Math.ceil(e.target.value.replace(/\D/g, '')))}
                    />
                    <div className={buttonNext ? classes.btn : classes.btnOff} onClick={pageIncrease}>
                        Next &raquo;
                    </div>
                </ul>
            </div>
        </>
    )
}
