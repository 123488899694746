import React, { useState } from 'react'
import classes from './Password.module.css'

const Password = ({ password, setPassword, setConfirmPass, confirmPass, token, id, api }) => {
    const [submitStatus, setSubmitStatus] = useState(false)
    const [msg, setMsg] = useState('')
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (password === confirmPass) {
        } else {
            setSubmitStatus(true)
            setMsg('Password and Confirm password did not match!')
            setTimeout(() => {
                setSubmitStatus(false)
            }, 2000)
        }
    }
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className={classes.wrapper}>
                    <h1>Change Password</h1>
                    <div className={classes.innerContainer}>
                        <div className={classes.half}>
                            <div>
                                <p>Password</p>
                                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div>
                                <p>Confirm Password</p>
                                <input
                                    type="password"
                                    value={confirmPass}
                                    onChange={(e) => setConfirmPass(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    {submitStatus && (
                        <p
                            className={classes.submitMessage}
                            style={{ color: `${msg?.substring(0, 2) === 'Fo' ? '#5cb484' : '#a10f0f'}` }}>
                            {msg}
                        </p>
                    )}
                    <div className={classes.submit}>
                        <button>Submit</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Password
