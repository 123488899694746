import React from 'react'
import Loading from 'react-spinners/ScaleLoader'
import classes from './LoadingBar.module.css'

export default function LoadingBar(props) {
    return (
        <div className={classes.loading}>
            <Loading height={props.height ? props.height : 15} color={props.color ? props.color : 'var(--primary)'} />
        </div>
    )
}
