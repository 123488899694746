import React, { useState } from 'react'
import { FaEye } from 'react-icons/fa'
import { SlOptionsVertical } from 'react-icons/sl'
import classes from './Action.module.css'

const Action = ({ popup, setPopup }) => {
    const [open, setOpen] = useState(false)

    const handleClick = async (status) => {
        setOpen(false)
    }
    const handlePopup = () => {
        setPopup(!popup)
    }
    return (
        <div className={classes.container}>
            <button>
                <FaEye size={16} onClick={handlePopup} />
            </button>
            <button className={classes.optionButton} onClick={() => setOpen(!open)}>
                <SlOptionsVertical size={16} />
            </button>
            {open && (
                <div className={classes.status}>
                    <button className={classes.approve} onClick={() => handleClick('approve')}>
                        Approve
                    </button>
                    <button className={classes.reject} onClick={() => handleClick('reject')}>
                        Reject
                    </button>
                </div>
            )}
        </div>
    )
}

export default Action
