import React, { useEffect, useState } from 'react'
import { FaFile } from 'react-icons/fa'
import { MdFileDownload } from 'react-icons/md'
import { fileNameFormatter } from '../../../../utils/fileNameFormatter'
import classes from './Guarantor.module.css'

const Guarantor = ({ guarantorDetails, api, token, setRefetch }) => {
    const [link, setLink] = useState(null)

    const [name, setName] = useState(guarantorDetails?.name)
    const [gender, setGender] = useState(guarantorDetails?.gender)
    const [dob, setDob] = useState(guarantorDetails?.dob)
    const [present, setPresent] = useState(guarantorDetails?.present_address)
    const [permanent, setPermanent] = useState(guarantorDetails?.permanent_address)
    const [occupation, setOccupation] = useState(guarantorDetails?.occupation)
    const [income, setIncome] = useState(guarantorDetails?.monthly_income)
    const [asset, setAsset] = useState(guarantorDetails?.assets)
    const [nid, setNid] = useState(guarantorDetails?.nid_number)
    const [tin, setTin] = useState(guarantorDetails?.tin_number)
    const [relation, setRelation] = useState(guarantorDetails?.relationship)
    const [phone, setPhone] = useState(guarantorDetails?.phone)
    const [submitStatus, setSubmitStatus] = useState(false)
    const [msg, setMsg] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        const response = await fetch(`${api}/loans/guarantor/${guarantorDetails?.id}`, {
            headers: {
                Accept: 'appllication/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: 'PATCH',
            body: JSON.stringify({
                name: name,
                phone: phone,
                gender: gender,
                dob: dob,
                present_address: present,
                permanent_address: permanent,
                occupation: occupation,
                monthly_income: income,
                assets: asset,
                relationship: relation,
                tin_number: tin,
                nid_number: nid,
            }),
        })

        if (response.ok) {
            setSubmitStatus(true)
            setRefetch(true)
            setMsg('Form Updated!')
            setTimeout(() => {
                setSubmitStatus(false)
            }, 2000)
        } else {
            setSubmitStatus(true)
            setMsg('Failed to submit form, please try again later!')
        }
    }
    const handleOnClick = (string) => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/temporary/url?service_name=loan&file_string=${string}`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()

                if (response.ok) {
                    setLink(data?.temporary_url)
                } else {
                    setLink(null)
                }
            } catch (error) {}
        }
        fetchData()
    }
    useEffect(() => {
        if (link) {
            window.open(link, '_blank')
            setLink(null)
        }
    }, [link])
    return (
        <div className={classes.container}>
            <form onSubmit={handleSubmit}>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px' }}>
                    <div className={classes.innerContainer}>
                        <p>Name of Guarantor</p>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                        <div className={classes.half}>
                            <div>
                                <p>Gender</p>
                                <input type="text" value={gender} onChange={(e) => setGender(e.target.value)} />
                            </div>
                            <div>
                                <p>Date of Birth</p>
                                <input type="date" value={dob} onChange={(e) => setDob(e.target.value)} />
                            </div>
                        </div>
                        <p>Present Address</p>
                        <input type="text" value={present} onChange={(e) => setPresent(e.target.value)} />
                        <p>Permanent Address</p>
                        <input type="text" value={permanent} onChange={(e) => setPermanent(e.target.value)} />
                        <p>Phone</p>
                        <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        <div className={classes.half}>
                            <div>
                                <p>Occupation</p>
                                <input type="text" value={occupation} onChange={(e) => setOccupation(e.target.value)} />
                            </div>
                            <div>
                                <p>Monthly Income</p>
                                <input type="text" value={income} onChange={(e) => setIncome(e.target.value)} />
                            </div>
                        </div>
                        <div className={classes.half}>
                            <div>
                                <p>Amount of Guarantor Asset</p>
                                <input type="text" value={asset} onChange={(e) => setAsset(e.target.value)} />
                            </div>
                            <div>
                                <p>Relationship with Applicant</p>
                                <input type="text" value={relation} onChange={(e) => setRelation(e.target.value)} />
                            </div>
                        </div>
                        <div className={classes.half}>
                            <div>
                                <p>NID</p>
                                <input type="text" value={nid} onChange={(e) => setNid(e.target.value)} />
                            </div>
                            <div>
                                <p>TIN</p>
                                <input type="text" value={tin} onChange={(e) => setTin(e.target.value)} />
                            </div>
                        </div>
                        <p>Uploaded Files</p>
                        <div className={classes.upload}>
                            <div>
                                <FaFile size={16} /> {fileNameFormatter(guarantorDetails?.nid_string || '')}
                            </div>
                            <button onClick={() => handleOnClick(guarantorDetails?.nid_string)}>
                                <MdFileDownload size={16} />
                            </button>
                        </div>
                        <div className={classes.upload}>
                            <div>
                                <FaFile size={16} /> {fileNameFormatter(guarantorDetails?.tin_string || '')}
                            </div>
                            <button onClick={() => handleOnClick(guarantorDetails?.tin_string)}>
                                <MdFileDownload size={16} />
                            </button>
                        </div>
                    </div>
                    {submitStatus && (
                        <p
                            className={classes.submitMessage}
                            style={{ color: `${msg?.substring(0, 2) === 'Fo' ? '#5cb484' : '#a10f0f'}` }}>
                            {msg}
                        </p>
                    )}
                    <div className={classes.buttonContainer}>
                        <button type="submit">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Guarantor
