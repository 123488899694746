import { faLeaf, faThumbtack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import state from '../../../utils/StateData.json'
import classes from './CreateSeller.module.css'

export default function CreateSeller(props) {
    return (
        <div>
            <div className={classes.formWrapper}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.logo}>Create New Seller</div>
                <div className={classes.form}>
                    <div className={classes.section}>
                        <FontAwesomeIcon className={classes.leaf} icon={faLeaf} />
                        Details Info
                    </div>
                    <div className={classes.innerWrap}>
                        <div className={classes.container}>
                            <form>
                                <ul>
                                    <li>
                                        <input type="checkbox" id="listItem1" />
                                        <label htmlFor="listItem1" className={classes.labelMain}>
                                            Basic Info
                                        </label>
                                        <ul>
                                            <div className={classes.innerContainer}>
                                                <div className={classes.collab}>
                                                    <div className={classes.formGrid}>
                                                        <label>
                                                            First Name <span className={classes.starSign}></span>
                                                            <input
                                                                type="text"
                                                                value={props.firstName}
                                                                onChange={(e) => props.setFirstName(e.target.value)}
                                                            />
                                                        </label>

                                                        <label>
                                                            Last Name <span className={classes.starSign}>*</span>
                                                            <input
                                                                type="text"
                                                                value={props.secondName}
                                                                required
                                                                onChange={(e) => props.setSecondName(e.target.value)}
                                                            />
                                                        </label>
                                                    </div>

                                                    <div className={classes.formGrid}>
                                                        <label>
                                                            Email
                                                            <input
                                                                type="email"
                                                                name="email"
                                                                value={props.email}
                                                                required
                                                                onChange={(e) => props.setEmail(e.target.value)}
                                                            />
                                                        </label>

                                                        <label>
                                                            Mobile <span className={classes.starSign}></span>
                                                            <input
                                                                type="tel"
                                                                name="phone"
                                                                minLength={11}
                                                                value={props.phone}
                                                                maxLength={11}
                                                                pattern="[0][1][0-9]{9}"
                                                                required
                                                                onChange={(e) => props.setPhone(e.target.value)}
                                                            />
                                                        </label>

                                                        <label>
                                                            Password <span className={classes.starSign}></span>
                                                            <input
                                                                type="password"
                                                                value={props.pass}
                                                                onChange={(e) => props.setPass(e.target.value)}
                                                            />
                                                        </label>

                                                        <label>
                                                            Confirm Password <span className={classes.starSign}></span>
                                                            <input
                                                                type="password"
                                                                value={props.confPass}
                                                                onChange={(e) => props.setconfPass(e.target.value)}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </ul>
                                    </li>
                                </ul>

                                {/* Second Form  */}
                                <ul>
                                    <li>
                                        <input type="checkbox" id="listItem2" />
                                        <label htmlFor="listItem2" className={classes.labelMain}>
                                            Seller Info
                                        </label>
                                        <ul>
                                            <div className={classes.innerContainer}>
                                                <div className={classes.collab}>
                                                    <label>
                                                        Company Name
                                                        <input
                                                            type="text"
                                                            name=""
                                                            value={props.companyName}
                                                            required
                                                            onChange={(e) => props.setCompanyName(e.target.value)}
                                                        />
                                                    </label>

                                                    <label>
                                                        Address
                                                        <input
                                                            type="text"
                                                            name="address"
                                                            required
                                                            value={props.address}
                                                            onChange={(e) => props.setAddress(e.target.value)}
                                                        />
                                                    </label>

                                                    <label>
                                                        About Company
                                                        <textarea
                                                            value={props.about}
                                                            className={classes.txtArea}
                                                            onChange={(e) => props.setAbout(e.target.value)}
                                                        />
                                                    </label>

                                                    <label>
                                                        Select Category
                                                        <select
                                                            className={classes.select}
                                                            name="category"
                                                            onChange={(e) => props.setIndustry(e.target.value)}>
                                                            <option>RMG & Accessories</option>
                                                            <option>Leather & Leather Product</option>
                                                            <option>Jute & Jute Product</option>
                                                            <option>Agro & Agro Based</option>
                                                            <option>Home Textilies </option>
                                                            <option>Handicraft </option>
                                                            <option>Food & Beverages</option>
                                                            <option>Furniture & Furnishing </option>
                                                            <option>Building Materials </option>
                                                            <option>Books, Stationary</option>
                                                            <option>Consumer Electronics </option>
                                                            <option>Fashion , Beauty </option>
                                                            <option> Packaging & Paper</option>
                                                            <option> Hospital & Medical </option>
                                                        </select>
                                                    </label>
                                                </div>

                                                <div className={classes.collab}>
                                                    <div className={classes.formGrid}>
                                                        <label>
                                                            Select District
                                                            <select
                                                                className={classes.select}
                                                                name="state_name"
                                                                value={props.stateName}
                                                                onChange={(e) => props.setStateName(e.target.value)}>
                                                                {state.map((geState, index) => (
                                                                    <option value={geState.state_name} key={index}>
                                                                        {geState.state_name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </label>

                                                        <label>
                                                            Contact Person Name
                                                            <input
                                                                type="text"
                                                                name="contactPerson"
                                                                required
                                                                value={props.pocName}
                                                                onChange={(e) => props.setPocName(e.target.value)}
                                                            />
                                                        </label>

                                                        <label>
                                                            Select Designation
                                                            <select
                                                                className={classes.select}
                                                                name="designation"
                                                                onChange={(e) => props.setDesignation(e.target.value)}>
                                                                <option value="Owner">Owner</option>
                                                                <option value="Manager">Manager</option>
                                                                <option value="Employees">Employees</option>
                                                            </select>
                                                        </label>
                                                        <label>
                                                            Contact Person Number
                                                            <input
                                                                type="number"
                                                                name="contactPersonNumber"
                                                                required
                                                                value={props.pocNumber}
                                                                onChange={(e) => props.setPocNumber(e.target.value)}
                                                            />
                                                        </label>
                                                    </div>

                                                    <div className={classes.formGrid}>
                                                        <label>
                                                            Select Logo
                                                            <input
                                                                type="file"
                                                                accept="image"
                                                                required
                                                                onChange={(e) => props.setImageFile(e.target.files[0])}
                                                            />
                                                        </label>

                                                        <label>
                                                            Select Banner
                                                            <input
                                                                type="file"
                                                                accept="image"
                                                                required
                                                                onChange={(e) => props.setImageFile2(e.target.files[0])}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </ul>
                                    </li>
                                </ul>

                                <ul>
                                    <li>
                                        <input type="checkbox" id="listItem3" />
                                        <label htmlFor="listItem3" className={classes.labelMain}>
                                            Company
                                        </label>
                                    </li>
                                </ul>
                                <button type="submit" className={classes.button}>
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
