import React, { useState } from 'react'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import classes from './UserPassword.module.css'

const UserPassword = () => {
    const [submitStatus, setSubmitStatus] = useState(false)
    const [msg, setMsg] = useState('')
    const [updateUser, setUpdateUser] = useState('')

    const [userPass, setUserPass] = useState('')
    const [phone, setPhone] = useState('')
    const [confirmUser, setConfirmUser] = useState('')
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (userPass === confirmUser) {
        } else {
            setSubmitStatus(true)
            setMsg('Password and Confirm password did not match!')
            setTimeout(() => {
                setSubmitStatus(false)
            }, 2000)
        }
    }
    return (
        <div className={classes.container}>
            <form onSubmit={handleSubmit}>
                <div className={classes.wrapper}>
                    <h1>Update User Password</h1>
                    <div className={classes.innerContainer}>
                        <div className={classes.selectContainer}>
                            <p>Select For Update</p>
                            <select
                                className={classes.selector}
                                defaultValue={'DEFAULT'}
                                onChange={(e) => setUpdateUser(e.target.value)}>
                                <option disabled value="DEFAULT">
                                    Select
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                            <MdOutlineKeyboardArrowDown className={classes.selectArrow} size={20} />
                        </div>
                        {updateUser === 'yes' && (
                            <div>
                                <div className={classes.half}>
                                    <div>
                                        <p>Search by Phone Number</p>
                                        <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                    </div>
                                    <div>
                                        <p>User Name</p>

                                        <div className={classes.fixedField}></div>
                                    </div>
                                </div>
                                <div className={classes.half}>
                                    <div>
                                        <p>Password</p>
                                        <input
                                            type="password"
                                            value={userPass}
                                            onChange={(e) => setUserPass(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <p>Confirm Password</p>
                                        <input
                                            type="password"
                                            value={confirmUser}
                                            onChange={(e) => setConfirmUser(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {submitStatus && (
                        <p
                            className={classes.submitMessage}
                            style={{ color: `${msg?.substring(0, 2) === 'Fo' ? '#5cb484' : '#a10f0f'}` }}>
                            {msg}
                        </p>
                    )}
                    <div className={classes.submit}>
                        <button type="submit">Update</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default UserPassword
